import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
import finance from "../views/finance.vue";
import qualifications from "../views/qualifications.vue";
import classroom from "../views/classroom.vue";
import propertyRight from "../views/propertyRight.vue";
import question from "../views/question.vue";
import us from "../views/us.vue";
import newRead from '@/views/newRead.vue'
import evaluate from '@/views/evaluate.vue'
import register from '@/views/register.vue'
import mregister from '@/views/mregister.vue'
import information from '@/views/information.vue'



import mIndex from '@/views/mobile/index.vue'




// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
	return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

let routes = []
const userAgent = navigator.userAgent || navigator.vendor || window.opera;
// 检查是否为移动设备
if (/Mobi|Android|Tablet|iPad|MediaPad|PlayBook|Silk|Kindle/i.test(userAgent)) {
	routes = [
		{
			path: "/",
			name: "mIndex",
			component: mIndex,
			meta: {
				title: "首页",
			},
		},
	]
}
// 假设剩下的都是 PC 设备
else {
	routes = [
		{
			path: "/",
			name: "index",
			component: index,
			meta: {
				title: "首页",
			},
		},
		{
			path: "/register",
			name: "register",
			component: register,
			meta: {
				title: "广西壮族自治区社会保险电子表单在线验证平台",
			},
		},
		{
			path: "/mregister",
			name: "mregister",
			component: mregister,
			meta: {
				title: "广西壮族自治区社会保险电子表单在线验证平台",
			},
		},
		{
			path: "/information",
			name: "information",
			component: information,
			meta: {
				title: "创业前沿资讯",
			},
		},

		{
			path: "/finance",
			name: "finance",
			component: finance,
			meta: {
				title: "工商财税",
			},
		},
		{
			path: "/qualifications",
			name: "qualifications",
			component: qualifications,
			meta: {
				title: "资质办理",
			},
		},
		{
			path: "/evaluate",
			name: "evaluate",
			component: evaluate,
			meta: {
				title: "禾异企服满意度调查问卷",
			},
		},

		{
			path: "/classroom/:id?",
			name: "classroom",
			component: classroom,
			meta: {
				title: "知识课堂",
			},
		},

		{
			path: "/propertyRight/:id?",
			name: "propertyRight",
			component: propertyRight,
			meta: {
				title: "知识产权",
			},
		},
		{
			path: "/question",
			name: "question",
			component: question,
			meta: {
				title: "常见问题",
			},
		},
		{
			path: "/us/:id?",
			name: "us",
			component: us,
			meta: {
				title: "关于我们",
			},
		},

		{
			path: "/newRead",
			name: "newRead",
			component: newRead,
			meta: {
				title: "新闻详情",
			},
		},
	];
}



// title

const router = new VueRouter({
	// mode: 'history',
	routes,
	// 跳转页面后 回到顶部
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		};
	},
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
	document.title = to.meta.title ? to.meta.title : defaultTitle;
	next();
});

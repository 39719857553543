<template>
  <div>
    <Nav></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div style="width: 1600px; margin: 150px auto 100px; height: 1000px">
        <div style="margin-right: 30px">
          <div class="content">
            <div class="nameStyle">{{ obj.title }}</div>
            <div class="time">
              <span>发布时间：{{ obj.createTime }} </span>
              <span style="margin: 0 35px">作者：爱听企服</span>
            </div>
            <div v-html="obj.content"></div>

            <!-- <div
              style="margin: 70px 0 50px; display: flex; align-items: center"
            >
              <div
                style="height: 1px; width: 40%; background-color: #ccc"
              ></div>
              <div
                style="
                  height: 80px;
                  width: 80px;
                  border-radius: 50%;
                  line-height: 80px;
                  text-align: center;
                  background-color: #ee3d33;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 0 4%;
                  border: 13px solid #fef1f1;
                "
              >
                列表
              </div>
              <div
                style="height: 1px; width: 40%; background-color: #ccc"
              ></div>
            </div> -->

            <!-- <div
                style="
                  display: flex;
                  align-items: center;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666;
                "
              >
                <div>上一页</div>
                <div style="margin-left: auto">下一页</div>
              </div> -->

            <!-- <div
                style="
                  display: flex;
                  align-items: center;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666;
                  margin-top: 10px;
                "
              >
                <div>合伙制改造一过程复杂、 结果简单的事</div>
                <div style="margin-left: auto">
                  合伙制改造一过程复杂、 结果简单的事
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      isNum: null,
      obj: {},
    };
  },
  created() {
    this.obj = JSON.parse(this.$route.query.params);
    console.log(JSON.parse(this.$route.query.params), "--------------");
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.isNum = now.params.id;
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    readBtn() {
      this.$router.push("/newRead");
    },
    changeBtn(val) {
      this.isNum = val;
    },
  },
};
</script>
<style scoped>
.htmlStyle {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 30px;
}
.time {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}
.content {
  border: 1px solid #D5D5D5;
  padding: 30px 70px;
}
.nameStyle {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
}
.rightTitle {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.rightCon {
  height: 90px;
  border-bottom: 1px solid #D5D5D5;
}
.rightContent {
  border: 1px solid #D5D5D5;
  margin-left: 30px;
}
.bg {
  text-align: center;
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (2).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="margin-bottom: 100px; margin-top: 50px">
        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            艺术品经营单位备案服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
        <div class="title" style="margin-bottom: 35px">
          艺术品经营单位备案办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "31",
      middleList: [
        {
          title: "哪些企业需要进行艺术品经营备案",
          content: [
            {
              one: "艺术品范围",
              two:
                "绘画作品、书法篆刻作品、雕塑雕刻作品、艺术摄影作品、装置艺术作品、工艺美术作品等及，上述作品的有限复制品，但不包括文物",
              three: "从事艺术品经营活动",
              four:
                "艺术品收购、销售、租赁经营单位，艺术品拍卖企业、进出口经营单位、商业性展览企业、鉴定评估机构，利用信息网络从事艺术品经营活动的电商平台企业、租赁平台企业等，以艺术品为标的物的投融资企业(含艺术品基金信托、艺术银行、交易所等)，以及其他从事艺术品经营活动的单位。",
              five: "艺术品经营备案要求",
              six:
                "已从事艺术品经营活动的单位，于2017年4月30日前，到所辖区行政服务中心办理备案手续",
            },
          ],
          remerk: "从事艺术品经营的企业都需要进行备案，不然属于违规",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "备案需要哪些材料",
          content: [
            {
              one: "1、艺术品经营单位备案申请表;",
              two:
                "2、营业执照或事业单位法人证书、民办非企业单位登记证书复印件;",
              three:
                "3、法定代表人或主要负责人身份证明复印件(营业执照或事业单位法人证书、民办非企业单位登记证书已载明姓名及身份证明编号的，可以不用提供);",
              four:
                "4、从事艺术品进出口经营活动的艺术品经营单位办理备案时，还应同时提交对外贸易经营者备案登记表复印件(或海关出具的ATA 单册证、《中华人民共和国海关报关企业报关注册登记证书》复印件)。特别提醒，所提交电请材料应按上述顺序排列;复印件应与原件一致，并加着公章:A4大小，有特殊规定除外。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未备案的单位处罚?",
          content: [
            {
              one:
                "《艺术品经营管理办法》第十九条规定，违反本办法第五条规定，设立从事艺术品经营活动的经营单位没有在领取营业执照之日起15日内，到其住所地县级以上人民政府文化行政部门备案的，由县级以上人民政府文化行政部门责令改正，并可根据情节轻重处以10000元以下罚款。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "艺术品经营单位备案",
      middleContent:
        "艺术品经营单位备案是指从事艺术品经营活动的单位，在依法申领营业执照后，需向当地县级以上人民政府文化行政部门进行登记备案的行为。这一制度依据的是《艺术品经营管理办法》等相关法律法规。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
}
.titles {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
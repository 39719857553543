<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      style="margin-top: 50px"
      :bottomTitle="bottomTitle"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "11",
      middleList: [
        {
          title: "10690码号/10691码号/SP码号详解",
          content: [
            {
              one: "1069短号码申请解决方案",
              two:
                "增值电信业务中1069短号码为跨省/全国范围内非经营性短消息类服务提供接入的第三方平台，是第三方企业(SP企业)用于短信服务，实现企业专有短号码，企业的短信显示号码统一，具有品牌效应。即形如10690XXX(X=0~9)，可扩展用于标识接入在平台后的内容提供主体，管理位长为8位。",
              three:
                "根据工信部(信息产业部令第28号令)《电信网码号资源管理办法》、《短消息类服务接入代码申请、分配、使用与收回管理办法》、《短消息类服务接入代码编号规划》相关规定:",
              four:
                "“非经营性短消息类服务”是特指向用户提供的短消息类服务只收取通信费，不能收取信息费。根据提供服务地域范围的不同，可以分为省内非经营性短消息类服务以及跨省/全国范围非经营性短消息类服务。提供这类服务的主体分为:",
              five:
                "Ø 为其他主体向用户提供非经营性短消息类服务提供统一接入到基础电信运营商网络的第三方平台，提供第三方平台的主体须取得增值电信业务经营许可证,,",
              six: "Ø 提供公益性短消息类服务的主体;",
              seven: "Ø 其他提供非经营性短消息类服务的主体。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "1069短号码特点:",
          content: [
            {
              one:
                "(1)1069短号为工信部批准，短号形象良好，有利于品牌客户品牌形象提升;",
              two: "(2)1069短号可支持20位扩展，拥有丰富的优势号段资源分配;",
              three: "(3)1069支持下游客户自由设置签名:",
              four: "(4)1069接入运营商后无通道资源占用费，减少企业运营成本;",
              five: "(5)1069短号为三网统一号码，提升企业品牌形象;",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "增值电信业务码号应用行业",
          content: [
            {
              one:
                "1、企业内部办公:内部通知，日程提醒、紧急会议信息等，与各地经销商、重点客户联系，发布产品促销通告，客户关系管理。",
              two:
                "2、行业协会、俱乐部:会员通知、会议通知、会员管理、最新消息。",
              three:
                "3、汽车销售中心:客户档案、促销信息、提车提醒、车保信息、优惠信息。",
              four:
                "4、银行:企业用户资金汇入、划出通知，银行卡资金异动信息，内部系统通知。",
              five:
                "5、物业:业主管理、服务通知、交费通知、临时通知(停水、电、气通告)。",
              six: "6、教育:办公通知、考勤通知、成绩通知、家长沟通、招生信息。",
              seven:
                "其他: 政府机关、货运、出租汽车公司、报业、学校、远程监控、数据采集等。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "申请1069短号码的优势",
          content: [
            {
              one: "(1)减少企业申请时间成本及办理成本:",
              two:
                "帮助多家品牌企业办理1069短号咨询及接入服务，积累了丰富的码号办理经验和资源，有效减轻企业公关成本及申请的时间成本，「启盈盈」申请仅需2-3个月。",
              three: "(2)优势码号选择权:",
              four:
                "帮助多家品牌企业办理优势优质短号，可为企业提供最优质码号资源。",
              five: "(3)顶级运营商资源:",
              six:
                "与三大运营商集团总部建立良好的合作关系，是全国大部分地区运营商的一级代理商，在行业短彩信、IDC、400等移动商务领域建立全面的战略合作关系，可为客户提供全国顶级运营商资源。",
              seven: "(4)短彩信平台:",
              eight:
                "经过多年的行业服务经验，凭借强大的技术团队自主研发企信通系列产品，采用JAVA语言编写，实现“智能化、可视化、模块化”，并可根据客户需要进行定制化开发。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "1069短号码申请条件",
          content: [
            {
              one: "1、企业法人营业执照复印件(电子版);",
              two:
                "2、第二类增值业务中的信息服务许可证(移动网信息服务业务(SP证))正文、附页、特别规定事项页及年检页:",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "1069短号码申请流程",
          content: [
            {
              one: "10690短号码申请/10691短号码申请/SP短号码申请",
              two:
                "1、与责公司共同核对公司基本必要材料的变化并做出正确修正。在取得完整公司基本材料后，「启盈盈」将在5个工作日内向工信部提交网审。工信部在30个工作日内给予审批通过。",
              three:
                "2、在1069短号码网审过通后，将在2个工作日向工信部提交完整纸质申请材料。工信部将在30个工作日后给予审批通过并在网上公告。1069短号码的申请将在70个工作日内完成。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "1069短号码落地",
          content: [
            {
              one: "10690短号码落地/10691短号码落地/SP短号码落地",
              two:
                "1、通道属性:支持上行下行，支持长短信(300字内容)，黑名单，码号可扩展，移动通道的子账号签名需要提前报备，联通和电信通道的签名自定义。",
              three: "2、项目进程规划:",
              four:
                "(1)运营商就申请落地材料，进行项目审核，大概在10个工作日左右",
              five: "(2)贵公司与运营商签定合作合同，需要3个工作日;",
              six: "(3)运营商做1069短号码入网，大概需要20个工作日:",
              seven: "(4)1069全网开通后，全网调试，大概需要10个工作;",
              eight:
                "(5)1069短信通道短信资费:根据发送量确定资费，最高移动每条4.0分，联通每条3分，电信每条3分",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "10690码号/10691码号/SP码号申请",
      middleContent:
        " 增值电信业务中1069短号码为跨省/全国范围内非经营性短消息类服务提供接入的第三方平台，是第三方企业(SP企业)用于短信服务，实现企业专有短号码，企业的短信显示号码统一，具有品牌效应。即形如10690XXX(X=0~9)，可扩展用于标识接入在平台后的内容提供主体，管理位长为8位。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
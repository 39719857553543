<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto 50px;
          padding-bottom: 30px;
        "
      >
        <div style="margin-bottom: 100px; padding: 0 50px 50px">
          <div class="title" style="margin-bottom: 20px">
            网络托管业务申请代理服务内容
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              margin-bottom: 25px;
              text-align: center;
            "
          >
            全程无须您亲自到场/双向快递、极速拿照
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            费用包含：网络托管业务许可证正本及附页；专业技术方案的提供、专业技术人员派遣、难点技术攻关、业务指导
          </div>
          <div style="text-align: center">
            <img src="@/assets/img/zzbl/seven/2.png" alt="" />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            <div>网络托管业务许可证样本</div>
            <div style="margin: 45px 0 10px">
              证书内容:名称、编号、导语、申办单位名称、法定代表人、注册资本、
            </div>
            <div>
              注册住所、业务种类、业务覆盖范围、签发人、发证机关盖章、发证日期、有效期。
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 90px">
        <div class="title" style="margin: 50px 0">
          办理网络托管业务需要准备的材料
        </div>
        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 10%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                01
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司法定代表人签署的经营增值电信业务的书面申请
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 6%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                02
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司的企业法人营业执照副本及复印件。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                03
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司概况。包括公司基本情况，拟从事增值电信业务的人员、场地和设施等情况
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                04
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司最近经会计师事务所审计的企业法人年度财务会计报告或验资报告，电信主管部门规定的其他相关会计资料
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                05
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司章程，公司股权结构及股东的有关情况
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                06
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司法定代表人签署的公司依法经营电信业务的承诺书
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                07
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请经营的电信业务依照法律、行政法规及国家有关规定须经有关主管部门事先审核同意的，应当提交有关主管部门审核同意的文件
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin: 0 40px 40px">
        <div class="title" style="margin-bottom: 40px">网络托管业务务明细</div>
        <div
          style="background-color: #fff; display: flex; justify-content: center"
        >
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/2.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              申请
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/3.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              变更/增项
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/4.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              续期/注销
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/5.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              转让
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          网络托管业务办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "7",
      middleList: [
        {
          title: "企业持有网络托管牌照可以开展哪些业务",
          content: [
            {
              one:
                "网络托管业务是指受用户委托，代管用户自有或租用的国内网络、网络元素或设备，包括为用户提供设备放置、网络管理、运行和维护服务，以及为用户提供互联互通和其他网络应用的管理和维护服务。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "我们公司有自建机房，可以开展网络托管业务吗",
          content: [
            {
              one: "只有取得网络托管业务牌照的企业才可以开展此业务",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "我们公司有自建机房，可以开展网络托管业务吗",
          content: [
            {
              one: "(一)经营者为依法设立的公司。",
              two:
                "(二)有与开展经营活动相适应的资金和专业人员(登高证、通信人员)。",
              three: "(三)有为用户提供长期服务的信誉或者能力。",
              four: "(四)省网业务的注册资本最低限额为100万元人民币。",
              five: "(五)全网业务的注册资金最低限额为1000万元人民币。",
              six: "(六)有必要的场地、设施及技术方案。",
              seven:
                "(七)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录",
              eight: "(八)国家规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "网络托管业务",
      middleContent:
        "网络托管业务是指受用户委托，代管用户自有或租用的国内网络、网络元素或设备，包括为用户提供设备放置、网络管理、运行和维护服务，以及为用户提供互联互通和其他网络应用的管理和维护服务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
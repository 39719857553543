<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "24",
      middleList: [
        {
          title: "信息系统安全等级保护备案办理机关",
          content: [
            {
              one: "县(市)级人民政府公安机关",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "信息系统安全等级保护备案范围",
          content: [
            {
              one:
                "凡是在中华人民共和国境内，通过物理通信信道直接或者间接与境外(含港、澳、台地区)的计算机信息系统进行联网的计算机信息系统的使用单位和个人均应当登记备案。",
            },
          ],
          remerk: "",
          icon: false,
          img: "25",
          iconImg: "",
        },
        {
          title: "等级保护定级",
          content: [
            {
              one:
                "第一级，信息系统受到破坏后，会对公民、法人和其它组织的合法权益造成损害，但不损害国家安全、社会秩序和公共利益。",
              two:
                "第二级，信息系统受到破坏后，会对公民、法人和其它组织的合法权益产生严重损害，或者对社会秩序和公共利益造成损害，但不损害国家安全。",
              three:
                "第三级，信息系统受到破坏后，会对社会秩序和公共利益造成严重损害，或者对国家安全造成损害。",
              four:
                "第四级，信息系统受到破坏后，会对社会秩序和公共利益造成特别严重损害，或者对国家安全造成严重损害。",
              five:
                "第五级，信息系统受到破坏后，会对国家安全造成特别严重损害。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "信息系统安全等级保护政策文档",
          content: [
            {
              one: "北京市公共服务网络与信息系统安全管理规定.doc",
              two:
                "公安部关于对与国际联网的计算机信息系统进行备案工作的通知.doc",
              three:
                "中华人民共和国计算机信息系统安全保护条例(国务院第147号).doc",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "信息系统安全等级保护测评",
          content: [
            {
              one: "测评依据",
              two: "GB/T28448-2012《信息系统安全等级保护测评要求》",
              three: "GB/T 28449-2012《信息系统安全等级保护测评过程指南》",
              four: "GB/T25058-2010《信息系统安全等级保护实施指南》",
              five: "GB/T25070-2010《信息系统等级保护安全设计技术要求》",
              six: "GB/T22240-2008《信息系统安全等级保护定级指南》",
              seven: "GB/T22239-2008《信息系统安全等级保护基本要求》",
              eight: "GB 17859-1999《计算机信息系统安全保护等级划分准则》",
            },
          ],
          remerk: "",
          icon: false,
          img: "26",
          iconImg: "",
        },
        {
          title: "等级保护基本要求",
          content: [
            {
              one:
                "1、基本安全要求是针对不同安全保护等级信息系统应该具有的基本安全保护能力提出的安全要求，根据实现方式的不同，基本安全要求分为基本技术要求和基本管理要求两大类。",
              two:
                "2、基本技术要求从物理安全、网络安全、主机安全、应用安全和数据安全几个层面提出;基本管理要求从安全管理制度、安全管理机构、人员安全管理、系统建设管理和系统运维管理几个方面提出，基本技术要求和基本管理要求是确保信息系统安全不可分割的两个部分。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "等级保护实施过程",
          content: [
            {
              one:
                "整个等级保护实施过程包括:系统定级、安全规划设计、安全实施/实现、安全运行管理与系统终止。",
              two:
                "系统定级:信息系统运营使用单位按照《信息系统信息安全等级保护定级指南》，确定信息系统安全等级。",
              three:
                "安全规划设计:根据信息系统的定级情况和安全需求等，设计合理的、满足等级保护要求的总体设计方案。",
              four:
                "安全实施/实现:按照信息系统总体方案书的总体要求，结合信息系统安全建设方案，分期分步落实安全措施。",
              five:
                "安全运行:在安全运行阶段主要是实施操作管理、变更管理和控制、安全状态监控、安全事件处置和应急预案、安全评估和持续改进以及监督检查等活动。",
              six:
                "系统终止:确保信息系统被转移、终止或废弃时，正确处理系统内的敏感信息。",
            },
          ],
          remerk: "",
          icon: false,
          img: "27",
          iconImg: "",
        },

        {
          title: "服务范围和内容",
          content: [
            {
              one:
                "针对等级保护实施过程中的各个阶段提供相应的安全服务。在系统定级阶段可协助识别信息系统的安全保护等级，进行信息系统安全等级的确认。安全总体规划阶段可提供具体的安全规划建议。安全设计与实施阶段可协助客户对安全措施进行落地与有效性的检验。安全运行维护阶段提供等级符合性检查、安全整改设计、应急预案及演练等服务。最终使客户信息系统达到国家等级保护基本要求。　",
            },
          ],
          remerk: "",
          icon: false,
          img: "28",
          iconImg: "",
        },
      ],
      middleTitle: "信息系统安全等级保护备案",
      middleContent:
        "信息系统安全等级保护是国家信息安全保障工作的基本制度、基本策略、基本方法。开展信息系统安全等级保护工作不仅是加强国家信息安全保障工作的重要内容，也是一项事关国家安全、社会稳定的政治任务。信息系统安全等级保护测评工作是指测评机构依据国家信息安全等级保护制度规定，按照有关管理规范和技术标准，对未涉及国家秘密的信息系统安全等级保护状况进行检测评估的活动。公安机关等安全监管部门进行信息安全等级保护监督检查时，系统运营使用单位必须提交由具有等级测评资质的机构出具的等级测评报告。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
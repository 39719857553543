<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
      <div style="background-color: #f4f4f4; padding: 0 20px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          办理域名备案材料提交至接入商操作需要的材料及流程
        </div>
        <div class="content">
          <div style="margin-top: 50px">
            如要办理ICP网站域名备案,请按以下流程办理:
          </div>
          <div>
            首先:去工信部网站http:/www.mitbeian.gov.cn/state/outPortal/loginPortal.action,右下角公共查询,确认备案类型
          </div>
          <div>一 公司备案需查询:</div>
          <div style="margin-left: 10px">
            1 输入主办单位名称,查询是否有过备案
          </div>
          <div style="margin-left: 10px">
            2
            输入主办单位证件类型公司营业执照号码,或组织机构代码证,查询是有有过备案
          </div>
          <div style="margin-left: 10px">3 输入域名,查询是否有过备案.</div>
          <div>二 个人备案需查询:</div>
          <div style="margin-left: 10px">1输入身份证号码.查询是否有过备案</div>
          <div style="margin-left: 10px">2 输入域名,查询是否有过备案.</div>
          <div>三 查询结果:</div>
          <div style="margin-left: 10px">
            1 主办单位和域名都没有备案号的,属于新增备案
          </div>
          <div style="margin-left: 10px">
            2 主办单位有备案,域名无备案的,属于添加网站,
          </div>
          <div style="margin-left: 10px">
            3 域名有备案,主办单位无备案,需把域名已有的备案号注销掉,做新增备案.
          </div>
          <div style="margin-left: 10px">
            4
            如果主办单位和域名是同一备案号,且信息准确,但不是通过我司申请的,现服务器迁入我司的,属新增接入
          </div>
          <div>四 备案所需材料:</div>
          <div style="margin-top: 50px">1公司备案所需资料:</div>
          <div style="margin-left: 30px">(1)网站真实性核验单</div>
          <div style="margin-left: 30px">
            (2)主办单位证件(企业单位证件为:营业执照副本复印件;事业单位、机关单位和社会团体证件为:组织机构代码证复印件或社团法人证书复印件)
          </div>
          <div style="margin-left: 30px">(3)法人和网站负责人身份证的复印件</div>
          <div style="margin-left: 30px">
            (4)授权书(网站负责人和法人不是同一人时)
          </div>
          <div style="margin-left: 30px">(5)域名证书</div>
          <div style="margin-left: 30px">(6)ICP备案信息登记/变更受理单.</div>
          <div style="margin-left: 30px">
            (7)网站负责人照片.(需照片地区:北京、山东、云南、重庆、辽宁、广西、新疆、湖北、贵州)
          </div>
          <div style="margin-left: 30px">
            注:所有资料一式两份,不得有涂改,备案资料要加盖单位公章;
          </div>
          <div style="margin-left: 50px">
            北京地区企业备案营业执照必须是有效的、最新年检过的
          </div>
          <div style="margin-left: 50px">
            北京、重庆照片要显示日期;重庆照片有效期为10天
          </div>
          <div style="margin-left: 50px">备案资料所有关于日期地方不要填写</div>
          <div style="margin-left: 50px">照片上要显示拍照日期</div>
          <div style="margin-left: 50px">
            核验单必须打印在一张A4纸上，不能分页;网站负责人签字必须是手写签名
          </div>
          <div style="margin-top: 50px">2 个人备案所需资料:</div>
          <div style="margin-left: 10px">(1)网站真实性核验单,</div>
          <div style="margin-left: 10px">(2)主办者身份证的复印件</div>
          <div style="margin-left: 10px">(3)域名证书</div>
          <div style="margin-left: 10px">(4)1CP备案信息登记/变更受理单</div>
          <div style="margin-left: 10px">
            (5)网站负责人照片,(需照片地区:北京、山东、云南、重庆、辽宁、广西、新疆、湖北、贵州、浙江)
          </div>
          <div style="margin-left: 10px">
            注:个人网站不能涉及单位、企业性质:网站名称不能以个人名字命名
          </div>
          <div style="margin-left: 30px">
            所有资料各一式两份，不得有涂改.，照片上要显示拍照日期:
          </div>
          <div style="margin-left: 30px">备案资料所有关于日期地方不要填写</div>
          <div style="margin-left: 30px">
            核验单必须打印在一张A4纸上，不能分页;网站负责人签字必须是手写签名
          </div>
          <div style="margin-left: 30px">
            通信地址必须详细到门牌号（如:XX省XX市XX区XX路XX号XX单元XX层XX室）
          </div>
          <div style="margin-top: 50px">
            3
            网站真实性核验单、ICP备案信息登记/变更受理单、授权书、核验单下载地址:
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          ICP域名备案核验单填写范例
        </div>
        <div style="margin: 30px 0">
          <img src="@/assets/img/zzbl/22/2.png" alt="" />
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          ICP域名备案核验单填写范例
        </div>
        <div style="margin: 30px 0">
          <img src="@/assets/img/zzbl/22/3.png" alt="" />
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          各省通信管理局域名备案要求
        </div>

        <div style="margin: 30px 0" class="content_title">
          上海通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、企业用户：主体信息需填写法人。若网站负责人非法人需提供法人授权书。
          </div>
          <div style="margin-left: 50px">
            2、医院：涉及医疗器械公司需提供前置审批文件。
          </div>
          <div style="margin-left: 50px">
            3、个人用户：上海管局要求：身份证非上海市，需要提有效的上海市暂住证或居住证原件正反面扫描件。
          </div>
          <div style="margin-left: 50px">
            4、个人用户：域名所有人需是备案主体姓名;单位用户：域名所有人需是公司全称或者法人姓名。必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">5、有效证件有效期需大于3个月。</div>
          <div style="margin-left: 50px">
            6、上海金融类网站，需要先去金融办备案，再到上海管局备案。相关文件如下：http://sjr.sh.gov.cn/ZhengWuDaTing/Detail?informationid=147874
          </div>
          <div style="margin-left: 50px">
            7、香港身份证不能作为备案证件，须提供香港通行证。
          </div>
          <div style="margin-left: 50px">
            8、管局规定：已有备案号的网站，需在网站底部中央位置标明正确的备案号并链接至工信部备案网址
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">无</div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          北京通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:境外注册商注册的所有域名及国内注册商注册的境外域名(如:.hk.us.top.w.me等)北京管局一律不予通过;境内注册商注册的国内域名和部分通用国际域名，(如:.com.net.cc.info.wang)可通过北京管局提交备案。
          </div>
          <div style="margin-left: 50px">
            2、主体性质不能直接变更，例如个人主体变更为公司主体，只能先注销原备案号后重新以公司名义再重新进行备案。
          </div>
          <div style="margin-left: 50px">
            3、北京个体工商户:若执照上显示具体名称则按企业名义备案;若执照上不显示具体名称(列如***)则按个人名义备案。
          </div>
          <div style="margin-left: 50px">
            4、网站负责人核验照片衣着需与当前备案季节相符。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          重庆通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、企业用户:主体信息需填写法人;网站名称必须填写公司名称即备案主体名称。
          </div>
          <div style="margin-left: 50px">
            2、个人用户:有""办公电话""则请填写，若无""办公电话""，需备注一个紧急联系人的手机号码及联系人姓名;网站名称需真实体现网站内容。
          </div>
          <div style="margin-left: 50px">3、一个网站对应一个域名分开填写。</div>
          <div style="margin-left: 50px">
            4、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            5、个体工商户若字号名称为*号，主办单位名称请以'经营者姓名(个体工商户)"命名，网站名称可以填写公章上的名称，核验单需加盖公章。
          </div>
          <div style="margin-left: 50px">6、不接受外省个人用户备案。</div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          福建通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:域名所有者需是备案主体。且必须关闭WHOIS信息隐藏
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          广东通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、个人用户:需在网站备注栏备注说明网站实质内容;
          </div>
          <div style="margin-left: 50px">
            个人用户请注意:需在核验单最下方手抄承诺书:""本人已履行网站备案信息当面核验手续，承认网站备案信息和核验记录真实有效，承诺本网站是个人网站，未含企业、单位等非个人网站的信息，承诺网站
            备案信息一旦发生变更，将及时进行更新，填报虚假备案信息、未履行备案变更手续、超出备案项目提供服务的，愿承担关闭网站并注销备案(列黑名单)等相应处理。""
          </div>
          <div style="margin-left: 50px">
            3、个体工商户备案，没有工商字号的以个人名义备案，并备注个体工商户。
          </div>
          <div style="margin-left: 50px">
            4、一个网站对应一个域名分开填写，网站名称需要与网站实际内容相符。
          </div>
          <div style="margin-left: 50px">
            5、管局规定:新域名备案期间网站不允许访问，否则不予通过。已有备案号的网站，需处于可访问状态，网站内容需与备案主体相符;同时需在网站底部中央位置标明正确的备案号并链接至工信部备案网址:www.miitbeian.gov.cn;
          </div>
          <div style="margin-left: 50px">
            个人用户备案域名数量超过3个(含3个)，公司用户备案域名数量超过30个(含30个)的，须补充提供主办单位证件、网站建设方案书、所有域名证书、接入服务合同、核验照片邮寄至广州市天河区林和西路9号耀中广场A座3717室美橙互联广州服务中心。
          </div>
          <div style="margin-left: 50px">
            6、域名所有人需于备案主体一致。且必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">
            7、法人为香港、澳门同胞的，备案时只能使用港澳通行证，不能用护照;法人为台湾同胞的，需使用台胞证
          </div>
          <div style="margin-left: 50px">
            8、申请的事项涉及从事药品和医疗器械类互联网信息服务，根据《互联网信息服务管理办法》第五条及《非经营性互联网信息服务管理办法》第十一条规定，应当依法经省食品药品监督管理局审核同意，并向我局提交该批准文件。(省食品药品监督管理局通信地址:广州市东风东路753号之二:邮编:510080:联系电话:020-37885172)。
          </div>
          <div style="margin-left: 50px">
            9、申请的网站涉及医疗保健类互联网信息服务，需提供《医疗机构执业许可证》或《医疗广告审查证明》给接入商核实，接入商核实后在网站备注中注明何时时已取得何卫生部门颁发的何种证件，并注明证件编号及有效期。
          </div>
          <div style="margin-left: 50px">10、未满18周岁不能办理备案。</div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          广东通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、个人用户:需在网站备注栏备注说明网站实质内容;
          </div>
          <div style="margin-left: 50px">
            个人用户请注意:需在核验单最下方手抄承诺书:""本人已履行网站备案信息当面核验手续，承认网站备案信息和核验记录真实有效，承诺本网站是个人网站，未含企业、单位等非个人网站的信息，承诺网站
            备案信息一旦发生变更，将及时进行更新，填报虚假备案信息、未履行备案变更手续、超出备案项目提供服务的，愿承担关闭网站并注销备案(列黑名单)等相应处理。""
          </div>
          <div style="margin-left: 50px">
            3、个体工商户备案，没有工商字号的以个人名义备案，并备注个体工商户。
          </div>
          <div style="margin-left: 50px">
            4、一个网站对应一个域名分开填写，网站名称需要与网站实际内容相符。
          </div>
          <div style="margin-left: 50px">
            5、管局规定:新域名备案期间网站不允许访问，否则不予通过。已有备案号的网站，需处于可访问状态，网站内容需与备案主体相符;同时需在网站底部中央位置标明正确的备案号并链接至工信部备案网址:www.miitbeian.gov.cn;
          </div>
          <div style="margin-left: 50px">
            个人用户备案域名数量超过3个(含3个)，公司用户备案域名数量超过30个(含30个)的，须补充提供主办单位证件、网站建设方案书、所有域名证书、接入服务合同、核验照片邮寄至广州市天河区林和西路9号耀中广场A座3717室美橙互联广州服务中心。
          </div>
          <div style="margin-left: 50px">
            6、域名所有人需于备案主体一致。且必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">
            7、法人为香港、澳门同胞的，备案时只能使用港澳通行证，不能用护照;法人为台湾同胞的，需使用台胞证
          </div>
          <div style="margin-left: 50px">
            8、申请的事项涉及从事药品和医疗器械类互联网信息服务，根据《互联网信息服务管理办法》第五条及《非经营性互联网信息服务管理办法》第十一条规定，应当依法经省食品药品监督管理局审核同意，并向我局提交该批准文件。(省食品药品监督管理局通信地址:广州市东风东路753号之二:邮编:510080:联系电话:020-37885172)。
          </div>
          <div style="margin-left: 50px">
            9、申请的网站涉及医疗保健类互联网信息服务，需提供《医疗机构执业许可证》或《医疗广告审查证明》给接入商核实，接入商核实后在网站备注中注明何时时已取得何卫生部门颁发的何种证件，并注明证件编号及有效期。
          </div>
          <div style="margin-left: 50px">10、未满18周岁不能办理备案。</div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          广西通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:一个网站信息内，只能填写一个网站域名;若有多个网站域名，须分开对不同域名进行新增网站。(如:abc.com、bca.com请分开填写新增网站)。
          </div>
          <div style="margin-left: 50px">
            2、原个人性质主体若要变更为单位性质，原主体个人必须为现在单位的法人。
          </div>
          <div style="margin-left: 50px">
            3、个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是公司全称或者法人姓名。且必须关闭WHOIS信息隐藏。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          贵州通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、企业用户:主体信息需填写法人。若网站负责人非法人需提供法人授权书。
          </div>
          <div style="margin-left: 50px">
            2、个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是单位全称或者是法人姓名。必须关闭WHOIS信息隐藏
            ，且四个以上的域名需提供国际域名注册证书
          </div>
          <div style="margin-left: 50px">
            3、个人用户:贵州管局要求:身份证非贵州省，需要提有效的贵州省暂住证或居住证等证明文件。
          </div>
          <div style="margin-left: 50px">
            4、医院:涉及医疗器械公司需提供前置审批文件。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          河北通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            3、医院:涉及医疗器械公司需提供前置审批文件。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          湖北通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            2、企业用户:主体负责人必须为法人，如网站负责人非法人，则必须是法人授权的负责人，需提供授权书加盖公章(授权书与身份证一起上传)
          </div>
          <div style="margin-left: 50px">
            3、个人用户:湖北管局要求:身份证非湖北省，需要提有效的湖北省暂住证或居住证等证明文件或社保证明。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          广西通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:一个网站信息内，只能填写一个网站域名;若有多个网站域名，须分开对不同域名进行新增网站。(如:abc.com、bca.com请分开填写新增网站)。
          </div>
          <div style="margin-left: 50px">
            2、原个人性质主体若要变更为单位性质，原主体个人必须为现在单位的法人。
          </div>
          <div style="margin-left: 50px">
            3、个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是公司全称或者法人姓名。且必须关闭WHOIS信息隐藏。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          贵州通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、企业用户:主体信息需填写法人。若网站负责人非法人需提供法人授权书。
          </div>
          <div style="margin-left: 50px">
            2、个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是单位全称或者是法人姓名。必须关闭WHOIS信息隐藏
            ，且四个以上的域名需提供国际域名注册证书
          </div>
          <div style="margin-left: 50px">
            3、个人用户:贵州管局要求:身份证非贵州省，需要提有效的贵州省暂住证或居住证等证明文件。
          </div>
          <div style="margin-left: 50px">
            4、医院:涉及医疗器械公司需提供前置审批文件。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          河北通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            3、医院:涉及医疗器械公司需提供前置审批文件。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          湖北通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            2、企业用户:主体负责人必须为法人，如网站负责人非法人，则必须是法人授权的负责人，需提供授权书加盖公章(授权书与身份证一起上传)
          </div>
          <div style="margin-left: 50px">
            3、个人用户:湖北管局要求:身份证非湖北省，需要提有效的湖北省暂住证或居住证等证明文件或社保证明。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          吉林通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、域名所有者需与备案主体一致，(若企业备案，域名所有者可为法人或备案主体公司名)，且必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">
            2、个人用户:身份证非吉林省户籍，需要提供居住证。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          江苏通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:已有备案号的网站，需在网站底部中央位置标明正确的备案号并链接至工信部备案网址:www.mitbeian.gov.cn;
          </div>
          <div style="margin-left: 50px">
            2、企业用户:如主体负责人填的不是法人,则主体负责人和网站负责人请填写同一个人。
          </div>
          <div style="margin-left: 50px">
            3、个人用户:江苏管局要求:身份证非江苏省，需要提有效的江苏省暂住证或居住证等证明文件。(和身份证拼在一起上传)
          </div>
          <div style="margin-left: 50px">
            4、个人用户:需在网站备注栏备注说明网站实质内容，网站名称只能是:姓名+个人主页或博客的形式;(如:张三的博客/张三的主页)
          </div>
          <div style="margin-left: 50px">
            5、江苏管局要求必须提供备案域名的域名证书(在美橙注册的域名可以不用提供)，个人用户:域名所有者需与备案主体一致，企业用户;域名所有者可为备案主
            体或法人或网站负责人。
          </div>
          <div style="margin-left: 50px">
            6、备案变更主体:如原备案主体是法人，可从个人变更成企业;如原备案主体非法人，则需要提供法人授权书才可变更。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          江西通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、个人用户:江西管局要求:身份证非江西省，需要提有效的江西省暂住证或居住证等证明文件。
          </div>
          <div style="margin-left: 50px">
            2、个人用户:必须在核验单最下方网站负责人签字处加按手印。
          </div>
          <div style="margin-left: 50px">3、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            4、管局规定:新域名备案期间网站不允许访问，否则不予通过，
          </div>
          <div style="margin-left: 50px">
            5、个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是单位全称或者是法人姓名。且必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">
            6、个人不能变更为企业。主体性质不能直接变更，例如个人主体变更为公司主体，只能先注销原备案号后重新以公司名义再重新进行备案。
          </div>
          <div style="margin-left: 50px">
            7、江西“事业单位备案和变更，请至当地机构编制委员会办公室进行先行注册中文域名和申请网站标识工作。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          辽宁通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、个人用户:""办公电话""有则填写，若无""办公电话""，需填写一个紧急联系人的手机及联系人姓名。
          </div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          内蒙古通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、个人用户:需在网站备注栏备注说明网站实质内容。
          </div>
          <div style="margin-left: 50px">
            3、内蒙管局不接受身份证为外省的个人用户备案。
          </div>
          <div style="margin-left: 50px">4、新域名备案期间网站请关闭。</div>
          <div style="margin-left: 50px">
            5、内蒙古政府机关，事业单位要到到自治区编办办理前置审批，咨询电话0471-6944959,0471-6944051;前置审批文件同单位证件一起扫描上传。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          宁夏通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、域名所有者需与备案主体一致，且必须关闭WHOIS信息隐藏
          </div>
          <div style="margin-left: 50px">
            3、宁夏管局不接受身份证为外省的个人用户备案。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          海通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、青海管局不接受身份证为外省的个人用户备案。
          </div>
          <div style="margin-left: 50px">2、新域名备案期间网站需关闭。</div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          四川通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、四川管局不接受身份证为外省的个人用户备案。
          </div>
          <div style="margin-left: 50px">
            2、无字号的个体工商户按个人名义以身份证备案，网站名称处应该填写“真实的网站名称(个体工商户)”。
          </div>
          <div style="margin-left: 50px">
            3、单位用户:主体需填写法人信息，主体负责人非法人需提供法人授权书
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          山东通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、管局规定:一个网站信息内，只能填写一个网站域名;若有多个网站域名，须分开对不同域名进行新增网站。(如:abc.com、bca.com请分开填写新增网站)。
          </div>
          <div style="margin-left: 50px">
            2、企业用户:主体信息需填写法人。若法人非中国国籍，不能作为主体负责人，可以填写中国国籍网站负责人，并在主体信息栏备注说明:法人非中国国籍，委托***办理备案。
          </div>
          <div style="margin-left: 50px">
            3、个人用户:网站名称需体现网站实质内容，建议在网站备注实质内容;个人备案不得涉及企业、律师、医疗、论坛、经营性等其他个人不能开办的网站。
            备案成功后，若发现个人名义涉及企业及以上内容，我司将注销备案信息，暂停主机访问。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          陕西通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、域名所有者需与备案主体一致，必须关闭WHOIS信息隐藏。
          </div>
          <div style="margin-left: 50px">
            3、同一个主体:网站名称与之前已备案网站名称相同，请将新增加的网站添加到已备案网站的子站点下面，不要再增加新站点。
          </div>
          <div style="margin-left: 50px">4、核验照片需当季。</div>
          <div style="margin-left: 50px">
            5、个人不能变更为企业。主体性质不能直接变更，例如个人主体变更为公司主体，只能先注销原备案号后重新以公司名义再重新进行备案。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          天津通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、个人用户:"办公电话"项必须填写。
          </div>
          <div style="margin-left: 50px">
            3、网站负责人核验照片衣着需与当前备案季节相符。
          </div>
          <div style="margin-left: 50px">
            4、域个人用户:域名所有人需是备案主体姓名;单位用户:域名所有人需是公司全称或者法人姓名。且必须关闭WHOIS信息隐藏。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          新疆通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">
            1、新疆管局不接受身份证为外省的个人用户备案。
          </div>
          <div style="margin-left: 50px">
            2、域名所有者需与备案主体一致，且必须关闭WHOIS信息隐藏:
          </div>
          <div style="margin-left: 50px">
            3、个人用户:必须在核验单最下方网站负责人签字处加按手印。
          </div>
          <div style="margin-left: 50px">
            4、如果网站语言是唯语、蒙语等需要先到beian.gov.cn上备案通过后，我司才可以接入。
          </div>
          <div style="margin-left: 50px">
            5、根据自治区党委网信办的要求，新疆所有新增备案网站都必须取得网信办出具的党建确认函后，方可进行网站备案审核，请你到所在地的网信办办理党建确认，取得确认函后上传至备案系统中，自治区网信办的咨询电话为0991-6788009/017/039
          </div>
          <div style="margin-left: 50px"></div>
          <div style="margin-left: 50px"></div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          云南通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            3、资本管理有限公司，此类网站需要金融类网站请提交该企业所在地金融办开具的开业函。
          </div>
          <div style="margin-left: 50px">
            4、根据云教办【2012)58号规定，教育类网站只能在云南省内接入:5、根据云编办(211号文件】云南省党政机关今后要求必须在所在地编办机构办理前置审批后，再予以备案;办理后同单位证件一起上传;省编办联系电话:0871-63557966。
          </div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          浙江通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            3、个人用户:身份证非浙江省，需要提有效的浙江省居住证明文件。
          </div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          黑龙江通管局域名备案要求
        </div>
        <div class="content">
          <div style="margin-left: 50px">1、企业用户:主体信息需填写法人。</div>
          <div style="margin-left: 50px">
            2、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            3、网站负责人核验照片衣着需与当前备案季节相符。
          </div>
          <div style="margin-left: 50px">4、未满18周岁不能办理备案</div>
          <div style="margin-left: 50px"></div>
        </div>

        <div style="margin: 30px 0" class="content_title">
          甘肃通管局域名备案要求
        </div>
        <div class="content" style="padding-bottom: 30px">
          <div style="margin-left: 50px">
            1、企业用户:主体信息需填写法人;网站名称必须填写公司名称即备案主体名称。
          </div>
          <div style="margin-left: 50px">2、一个网站对应一个域名分开填写。</div>
          <div style="margin-left: 50px">
            3、管局规定:新域名备案期间网站不允许访问，否则不予通过。
          </div>
          <div style="margin-left: 50px">
            4、甘肃管局不接受身份证为外省的个人用户备案。
          </div>
          <div style="margin-left: 50px">
            5、个体工商户若字号名称为*号，主办单位名称请以'经营者姓名(个体工商户)'命名，网站名称可以填写公章上的名称，核验单需加盖公章。
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "21",
      middleList: [
        {
          title: "为什么必须做域名备案",
          content: [
            {
              one:
                "域名备案又称ICP网站备案,目的就是为了防止在网上从事非法的网站经营活动，打击不良互联网信息的传播。根据中华人民共和国信息产业部第十二次部务会议审议通过的《非经营性互联网信息服务备案管理办法》条例，在中华人民共和国境内提供非经营性互联网信息服务，应当办理备案。未经备案，不得在中华人民共和国境内从事非经营性互联网信息服务。而对于没有备案的网站将予以罚款或关闭。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "域名备案申请流程",
          content: [
            {
              one: "域名备案信息审核需要经过三个步骤:",
              two: "1、服务器接入商(IDC企业或ISP企业)审核",
              three: "2、待提交至通管局审核:",
              four:
                "表示您提交的备案信息已通过ISP接入商审核,备案系统正在向通管局系统提交您的备案信息,备案系统会显示“待提交至通管局审核”，提交时间取决于通管局的接口可靠性，正常时间在一天以内;",
              five: "3、待通管局审核",
              six:
                "表示备案信息已成功提交至工信部接口,通管局正在接收数据或审核，审核时间一般为20个工作日。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "域名备案/网站备案/ICP网站备案",
      middleContent:
        "域名备案又称ICP网站备案,目的就是为了防止在网上从事非法的网站经营活动，打击不良互联网信息的传播。 根据中华人民共和国信息产业部第十二次部务会议审议通过的《非经营性互联网信息服务备案管理办法》条例，在中华人民共和国境内提供非经营性互联网信息服务，应当办理备案。未经备案，不得在中华人民共和国境内从事非经营性互联网信息服务。而对于没有备案的网站将予以罚款或关闭。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      style="margin-top: 50px"
      :bottomTitle="bottomTitle"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "10",
      middleList: [
        {
          title: "950/951/952短号码介绍",
          content: [
            {
              one:
                "95短号码是电信号码资源，是可以呼入呼出都显示一个5或6位的95短电话号码，由国家工业和信息化部负责全国号码资源的统一管理和核配工作，属于国家紧缺性电信资源，用户在全国任可地方都可以拨打短号码，并且不用加区号;根据企业实际情况，帮助企业实现95电话在全国各地可以拨打，提升企业品牌形象。",
              two: "短号码位长:950、951是5位短号码，952是6位短号码;",
              three: "工信部所收占用费:950、951是24万/年:952是2.4万/年:",
              four:
                "短号码特点:位数少，易记，易推广，全国唯一号码，具有品牌效应;。",
              five: "申请条件:具有跨地区呼叫中心资质;",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "增值电信业务中95短号码申请流程:",
          content: [
            {
              one:
                "1、将与责公司共同核对公司基本必要材料的变化并做出正确修正。在取得完整公司基本材料后，「启盈盈」将在5个工作日内向工信部提交网审。工信部在40个工作日内给予审批通过。",
              two:
                "2、在95短号码网审过通后，将在2个工作日向工信部提交完整纸质申请材料。工信部将在30个工作日后给予审批通过并在网上公告。95短号码的申请将在90个工作日内完成。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "95短号码电话接入",
          content: [
            {
              one:
                "将根据贵公司呼叫中心机房特点，为贵公司开通95短号码电话。开通的95短号码电话特点如下:",
              two:
                "(1)一点接入全国开通，不必要全国31省市各个开通，节省呼叫中心资源，更节省开通时间:",
              three: "(2)开通快，全国开通只需要30-60个工作日;",
              four: "(3)良好的运营商关系，话费低;",
              five: "(4)全国任意地区直接拨打95短号码，不用加区号;",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "申请95号码的优势",
          content: [
            {
              one: "(1)减少企业申请时间成本及办理成本:",
              two:
                "帮助多家品牌企业办理95短号咨询及接入服务，积累了丰富的码号办理经验和资源，有效减轻企业公关成本及申请的时间成本，「启盈盈」申请仅需2-3个月。",
              three: "(2)优势码号选择权:",
              four:
                "帮助多家品牌企业办理优势优质短号，可为企业提供最优质码号资源。",
              five: "(3)顶级运营商资源:",
              six:
                "与三大运营商集团总部建立良好的合作关系，是全国大部分地区运营商的一级代理商，在行业短彩信、IDC、400等移动商务领域建立全面的战略合作关系，可为客户提供全国顶级运营商资源。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "95码号申请",
      middleContent:
        " 为企业提供增值电信业务经营许可证申请咨询、950/951/952/953/955/10690/10698短号码申请咨询、落地、年检的一条龙服务增值电信业务服务商。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div style="margin-top: 120px;">
    <Nav></Nav>

    <one v-if="active == 0"></one>

    <two v-if="active == 1"></two>

    <three v-if="active == 2"></three>

    <four v-if="active == 3"></four>

    <five v-if="active == 4"></five>

    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
import one from "@/views/components/propertyRight/one";
import two from "@/views/components/propertyRight/two";
import three from "@/views/components/propertyRight/three";
import four from "@/views/components/propertyRight/four";
import five from "@/views/components/propertyRight/five";


export default {
  components: {
    Footer,
    Nav,
    one,
    two,
    three,
    four,
    five
  },
  data() {
    return {
      active: "",
    };
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.active = now.params.id;
        console.log(now, "=====");
      },
      immediate: true, //立即执行
    },
  },
  created() {
    this.active = this.$route.params.id;
  },
};
</script>
<style scoped>
.top_btn {
  border-radius: 21px;
  border: 1px solid #181818;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #707070;
  width: 215px;
  height: 36px;
  line-height: 36px;
  margin-left: 50px;
}
.top_contents {
  height: 70px;
  margin: 50px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.top_title {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #ee3d33;
  border: 1px solid #DEDEDE;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
.top_content {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
.top_content span {
  flex: 1;
}

.title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}
.num {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 29px;
  font-style: italic;
}
.g_last {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: -240px;
  background: linear-gradient(
    to right,
    #f68c5d,
    #fcb271,
    #fdd98e,
    #fbee9d,
    #fa9c63FF,
    #f6d792
  );
}
.kh_content {
  height: 300px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #DEDEDE;
}
.kh_content .first {
  flex: 1;
  text-align: center;
  border-right: 1px solid #DEDEDE;
  margin-right: 7px;
}
.kh_content div img {
  margin: 30px auto;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
      <div
        style="
          background-color: #f4f4f4;
          text-align: center;
          padding-bottom: 50px;
        "
      >
        <img src="@/assets/img/zzbl/ten/2.png" alt="" />
      </div>

      <div style="margin-bottom: 100px; margin-top: 50px">
        <div class="title" style="margin-bottom: 35px">
          外商投资电信业务审批流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "9",
      middleList: [
        {
          title: "外商投资经营电信业务审批办理机构",
          content: [
            {
              one: "实施主体:工业和信息化部",
              two: "受理单位:工业和信息化部信息通信发展司政策标准处",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "外商投资经营电信业务审批设定依据?",
          content: [
            {
              one: "设立和规范项目实施的法律、法规及规范性文件:",
              two: "(一)《外商投资电信企业管理规定》(国务院第534号令)",
              three:
                "(二)《工业和信息化部行政许可实施办法》(工业和信息化部2号部令)",
              four:
                "外资ICP-经营增值电信业务的外商投资电信企业的外方投资者在企业中的出资比例，最终不得超过50%。",
              five:
                "外资ED1-经营增值电信业务的外商投资电信企业的外方投资者在企业中的出资比例，可以开放至100%(类似于淘宝、天猫)",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "外商投资电信企业办理条件",
          content: [
            {
              one:
                "1.经营全国的或者跨省、自治区、直辖市范围的基础电信业务的，其注册资本最低限额为10亿元人民币。:",
              two: "2.经营增值电信业务的，其注册资本最低限额为1000万元人民币。",
              three:
                "3.经营省、自治区、直辖市范围内的基础电信业务的，其注册资本最低限额为1亿元人民币;经营增值电信业务的，其注册资本最低限额为100万元人民币。",
              four:
                "4.经营基础电信业务(无线寻呼业务除外)的外商投资电信企业的外方投资者在企业中的出资比例，最终不得超过49%。",
              five:
                "5.经营增值电信业务(包括基础电信业务中的无线寻呼业务)的外商投资电信企业的外方投资者在企业中的出资比例，最终不得超过50%。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "传真存储转发业务",
          content: [
            {
              one:
                "传真存储转发业务是指在用户的传真机与传真机、传真机与计算机之间设立存储转发系统，用户间的传真经存储转发系统的控制，非实时地传送到对端的业务。",
              two:
                "传真存储转发系统主要由传真工作站和传真存储转发信箱组成，两者之间通过分组网、数字专线、互联网连接。传真存储转发业务主要有多址投送、定时投送、传真信箱、指定接收人通信、报文存档及其他辅助功能等。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "办理外商投资电信业务审批",
      middleContent:
        " 外商投资电信企业可以经营基础电信业务、增值电信业务，具体业务分类依照电信条例的规定执行。  外商投资电信企业经营业务的地域范围,由国务院工业和信息化主管部门",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 0 20px 50px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          什么是游戏备案？
        </div>
        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          未经批准擅自从事经营性互联网文化活动，由相关部门对其进行查处并没收非法所得。
        </div>
        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          标准定义
        </div>

        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >国产网络游戏在上网运营之日起30日内应当按规定向国务院文化行政部门履行备案手续相关游戏出版运营的批文号的简称
        </div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          审批机构
        </div>

        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >根据《网络游戏管理暂行办法》的规定，审批机构为文化部文化市场司网络处
        </div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          强制规定
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >根据相关政策要求在游戏上线运营后的60日内务必到文化部做游戏运营备案
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 50px;
          "
        >
          <div class="title"></div>
          需要哪些条件/资料才能办理呢？
        </div>
        <div class="box_contents">
          注：因办理地区不同，办理条件和申请资料也会有所不同，具体可以
          <span style="color: #EE3D33">咨询顾问</span>
        </div>
        <div
          class="box_contents"
          style="
            font-size: 18px;
            font-weight: bold;
            color: #333333;
            margin: 15px 0;
          "
        >
          办理条件
        </div>

        <div class="box_contents">
          · 申报单位已取得文化厅颁发的《网络文化经营可证》俗称文网文
        </div>
        <div class="box_contents">· 申报单位的产品上网运营时间未超过30日</div>
        <div class="box_contents">· 需具备完整的业务发展计划及运营管理措施</div>
        <div class="box_contents">
          ·
          备案游戏产品需符合《互联网文化管理暂行规定》《网络游戏管理暂行办法》等相关规定
        </div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          办理条件
        </div>
        <div class="box_contents">· 营业执照副本复印件</div>
        <div class="box_contents">· 网络文化经营许可证</div>
        <div class="box_contents">· 软件著作权登记证书</div>
        <div class="box_contents">· 游戏主题内容说明及操作说明;</div>
        <div class="box_contents">
          · 游戏中对白、旁白、描述性文字及游戏主题曲、插曲等音频文件
        </div>
      </div>

      <div style="margin-bottom: 100px; margin-top: 50px">
        <div style="margin: 0 40px 40px">
          <div
            style="
              margin-bottom: 40px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
            "
          >
            游戏备案办理服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-bottom: 50px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 30px;
            color: #333333;
            text-align: center;
            margin-top: 50px;
          "
        >
          游戏备案办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "34",
      middleList: [],
      middleTitle: "游戏备案办理",
      middleContent:
        "游戏备案是指网络游戏上市所需的备案手续，游戏备案有两种，一种是游戏运营备案，一种是游戏出版备案，在这里特指游戏运营备案。游戏运营备案是文化部针对游戏内容的审核，在游戏产品正式上线以及收费之前需要先将给文化部的审批才可以上线运营。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.box_contents {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  margin: 20px 0;
}
.box_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.box_content {
  flex: 1;
  border: 1px solid #D2D2D2;
  padding: 10px;
  margin-right: 15px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
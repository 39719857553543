<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      style="margin-top: 50px"
      :bottomTitle="bottomTitle"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "15",
      middleList: [
        {
          title: "互联网新闻信息服务许可证在哪办理",
          content: [
            {
              one:
                "申请主体为中央新闻单位(含其控股的单位)或中央新闻宣传部门主管的单位的，由国家互联网信息办公室受理和决定;",
              two:
                "申请主体为地方新闻单位(含其控股的单位)或地方新闻宣传部门主管的单位的，由省、自治区、直辖市互联网信息办公室受理和决定;",
              three:
                "申请主体为其他单位的，经所在地省、自治区、直辖市互联网信息办公室受理和初审后，由国家互联网信息办公室决定",
            },
          ],
          remerk:
            "《互联网新闻信息服务许可证》有效期为三年。有效期届满，需继续从事互联网新闻信息服务活动的，应当于有效期届满三十日前申请续办。",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "不办理互联网新闻信息服务许可证擅自经营有什么后果",
          content: [
            {
              one:
                "未经许可或超越许可范围开展互联网新闻信息服务活动的，由国家和省、自治区、直辖市互联网信息办公室依据职责责令停止相关服务活动，处一万元以上三万元以下罚款。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理互联网新闻信息服务许可证申请条件",
          content: [
            {
              one: "(一)在中华人民共和国境内依法设立的法人;",
              two: "(二)主要负责人、总编辑是中国公民;",
              three:
                "(三)有与服务相适应的专职新闻编辑人员、内容审核人员和技术保障人员;",
              four: "(四)有健全的互联网新闻信息服务管理制度;",
              five: "(五)有健全的信息安全管理制度和安全可控的技术保障措施;",
              six: "(六)有与服务相适应的场所、设施和资金。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理互联网新闻信息服务许可证需要准备的材料",
          content: [
            {
              one: "(一)主要负责人、总编辑为中国公民的证明;",
              two:
                "(二)专职新闻编辑人员、内容审核人员和技术保障人员的资质情况;",
              three: "(三)互联网新闻信息服务管理制度;",
              four: "(四)信息安全管理制度和技术保障措施;",
              five: "(五)互联网新闻信息服务安全评估报告;",
              six: "(六)法人资格、场所、资金和股权结构等证明;",
              seven: "(七)法律法规规定的其他材料。",
              eight:
                "签定合同后，「爱听」将根据贵司实际情况提供更详细材料内容。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "互联网新闻信息服务许可证",
      middleContent:
        "互联网新闻信息服务，包括互联网新闻信息采编发布服务、转载服务、传播平台服务通过互联网站、应用程序、论坛、博客、微博客、公众账号、即时通信工具、网络直播等形式向社会公众提供互联网新闻信息服务，应当取得互联网新闻信息服务许可，禁止未经许可或超越许可范围开展互联网新闻信息服务活动。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
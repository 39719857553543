<template>
  <div>
    <div class="bg" style="position: relative">
      <div
        style="
          position: absolute;
          font-family: Source Han Sans CN;
          font-weight: 400;
          font-size: 18px;
          color: #FFFFFF;
          bottom: 70px;
          left: 360px;
        "
      >
        <div style="margin: 15px 0">
          外观设计可见于任何工业制造的商品，例如食品、药品、计算机、
        </div>
        <div>手机、电视、运动器械、卫浴器材、首饰、钟表、服装鞋帽等等。</div>
      </div>
      <div
        style="
          text-align: center;
          width: 500px;
          height: 385px;
          margin-left: auto;
          margin-right: 200px;
          margin-top: 30px;
        "
      >
        <div style="background-color: #EE3D33; padding: 15px; height: 150px">
          <div
            style="
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 36px;
              color: #FFFFFF;
            "
          >
            外观设计专利申请
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #FFFFFF;
              margin: 10px 0 25px;
            "
          >
            提供授权担保服务，不成功退款
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 30px;
              color: #FFFFFF;
            "
          >
            费用：¥799.00
          </div>
        </div>

        <div style="background-color: #fff; height: 255px">
          <div style="margin: 0 20px 20px">
            <div style="display: flex; align-items: center">
              <span style="color: #EE3D33; font-size: 18px">*</span>
              <span
                style="
                  width: 120px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                "
                >联系号码：</span
              >
              <el-input
                style="margin: 20px 0"
                placeholder="便于接手咨询结果，仅官方可见"
                v-model="value"
              ></el-input>
            </div>

            <div style="display: flex; align-items: center">
              <span
                style="
                  width: 130px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                "
                >联系人</span
              >
              <el-input
                placeholder="如王先生/李女士"
                v-model="value1"
              ></el-input>
            </div>

            <div
              style="
                text-align: center;
                background: #EE3D33;
                border-radius: 6px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF;
                padding: 15px 0;
                margin-top: 25px;
                cursor: pointer;
              "
              @click="submit"
            >
              确认办理
            </div>

            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                text-align: center;
                margin-top: 15px;
              "
            >
              注:保护产品外形,包装等，3-6个月授权,10年保护期
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1600px; margin: 0 auto">
      <div class="title">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利简介
      </div>
      <div class="content">
        <div>
          外观设计专利是指对产品的形状、图案或者其结合以及色彩与形状、图案的结合所作出的富有美感并适于工业应用的新设计。
        </div>
        <div>
          外观设计专利是专利权的客体，它与发明或实用新型完全不同，即外观设计不是技术方案。
        </div>
      </div>
      <div class="c_title">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        外观设计专利概述：
      </div>

      <div class="content">
        <div>
          外观设计专利是专利权的客体，是专利法保护的对象，是指依法应授予专利权的外观设计。它与发明或实用新型完全不同，即外观设计不是技术方案。中国《专利法》第二条中规定："外观设计，是指对产品的形状、图案或者其结合以及色彩与形状、图案的结合所做出的富有美感并适于工业应用的新设计。
        </div>
      </div>

      <div class="content">可见，外观设计专利应当符合以下要求:</div>

      <div class="contents">(1)是指形状、图案、色彩或者其结合的设计;</div>
      <div class="contents">最新版的外观设计专利证书</div>
      <div class="contents">最新版的外观设计专利证书</div>
      <div class="contents">(2)必须是对产品的外表所作的设计</div>
      <div class="contents">(3)必须富有美感;</div>
      <div class="contents">(4)必须是适于工业上的应用</div>

      <div
        style="
          border: 1px solid #A7C4FF;
          padding: 30px 30px 50px 50px;
          margin-top: 30px;
        "
      >
        <div class="title">外观设计专利应当符合以下要求:</div>
        <div style="display: flex">
          <div>
            <div>
              <div style="display: flex; align-items: center">
                <div
                  style="
                    width: 66px;
                    height: 66px;
                    line-height: 66px;
                    text-align: center;
                    background: #EE3D33;
                    border-radius: 50%;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                  "
                >
                  01
                </div>
                <div
                  style="
                    width: 471px;
                    height: 62px;
                    line-height: 62px;
                    background: #D5E3FF;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                    padding-left: 30px;
                  "
                >
                  是指形状、图案、色彩或者其结合的设计
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; align-items: center">
                <div
                  style="
                    width: 66px;
                    height: 66px;
                    line-height: 66px;
                    text-align: center;
                    background: #EE3D33;
                    border-radius: 50%;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                  "
                >
                  02
                </div>
                <div
                  style="
                    width: 471px;
                    height: 62px;
                    line-height: 62px;
                    background: #D5E3FF;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                    padding-left: 30px;
                    margin-top: 20px;
                  "
                >
                  必须是对产品的外表所作的设计
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; align-items: center">
                <div
                  style="
                    width: 66px;
                    height: 66px;
                    line-height: 66px;
                    text-align: center;
                    background: #EE3D33;
                    border-radius: 50%;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                  "
                >
                  03
                </div>
                <div
                  style="
                    width: 471px;
                    height: 62px;
                    line-height: 62px;
                    background: #D5E3FF;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                    padding-left: 30px;
                    margin-top: 20px;
                  "
                >
                  必须富有美感
                </div>
              </div>
            </div>
            <div>
              <div style="display: flex; align-items: center; margin-top: 20px">
                <div
                  style="
                    width: 66px;
                    height: 66px;
                    line-height: 66px;
                    text-align: center;
                    background: #EE3D33;
                    border-radius: 50%;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                  "
                >
                  04
                </div>
                <div
                  style="
                    width: 471px;
                    height: 62px;
                    line-height: 62px;
                    background: #D5E3FF;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                    padding-left: 30px;
                  "
                >
                  必须是适于工业.上的应用
                </div>
              </div>
            </div>
          </div>

          <div>
            <div>
              <img
                style="margin: 0 50px"
                src="@/assets/img/propertyRight/4/2.png"
                alt=""
              />
            </div>
            <div style="margin: 20px 0">
              <img
                style="margin: 0 50px"
                src="@/assets/img/propertyRight/4/3.png"
                alt=""
              />
            </div>
            <div>
              <img
                style="margin: 0 50px"
                src="@/assets/img/propertyRight/4/4.png"
                alt=""
              />
            </div>
          </div>

          <div>
            <div
              style="
                width: 603px;
                height: 86px;
                background: #FFF3F3;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                padding: 5px;
              "
            >
              授予专利权的外观设计,应当不属于现有设计;也没有任何单位或者个人就同样的外观设计在申请日以前向国务院专利行政部]提出过申请，并记载在申请日以后公告的专利文件中。
            </div>

            <div
              style="
                width: 603px;
                height: 86px;
                line-height: 86px;
                background: #f0f5ff;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                padding: 5px;
              "
            >
              授予专利权的外观设计与现有设计或者现有设计特征的组合相比,应当具有明显区别。
            </div>

            <div
              style="
                width: 603px;
                height: 86px;
                line-height: 86px;
                background: #FFF3F3;
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                padding: 5px;
              "
            >
              授予专利权的外观设计不得与他人在申请日以前已经取得的合法权利相冲突。
            </div>
          </div>
        </div>
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利范围
      </div>
      <div class="content">
        <div>
          通常，可以构成外观设计的组合有：产品的形状;产品的图案;产品的形状和图案;产品的形状和色彩;产品的图案和色彩;产品的形状、图案和色彩。
        </div>
      </div>
      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        工业产品形状含义
      </div>

      <div class="content">
        <div>
          工业产品外观形状是指对产品造型的设计，也就是指产品外部的点、线、面的移动、变化、组合而呈现的外表轮廓，即对产品的结构、外形等同时进行设计、制造的结果;
        </div>
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        工业产品外观图案含义
      </div>

      <div class="content">
        <div>
          工业产品外观图案是指由任何线条、文字、符号、色块的排列或组合而在产品的表面构成的图形。产品的外观图案应当是固定、可见的，而不应是时有时无的或者需要在特定的条件下才能看见。
        </div>
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        工业产品色彩含义
      </div>

      <div class="content">
        <div>
          工业产品的色彩是指用于产品上的颜色或者颜色的组合，制造该产品所用材料的本色不是外观设计的色彩。产品的色彩不能独立构成外观设计，除非产品色彩变化的本身已形成一种图案。
        </div>
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利提交文件
      </div>
      <div class="content">申请外观设计专利的文件包括：</div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;外观设计专利请求书、图片或者照片，各一式两份。要求保护色彩的，还应当提交彩色图片或者照片一式两份。提交图片的两份均为图片，提交照片的两份均为照片，不得将图片和照片混用。如对图片或照片需要说明的，应该提交外观设计简要说明一式两份。其中照片要的是这个产品的六面视图，(前视图、后视图、俯视图、仰视图、左视图、右视图)和立体图，要求保护图案的，应提交展开图和立体图;要求保护色彩的，应提交彩色和黑白照片或图片。图的大小在3×8厘米到15×22厘米之间。图片上不
        能出现阴影或虚线，照片的背景只能有一种颜色，而且照片上除了所要求的外观设计外，不能有其他任何别的物品。
        另外，不管提交的是图片还是照片，各视图都必须是正视图。
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利侵权判断
      </div>
      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        比对的主体
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;比对的主体外观设计专利产品是比发明和实用新型专利产品更具有日常生活性的商品，对于其中某些相近似产品的细微差别，普通消费者往往会忽略掉，而专业人员则很容易分辨出来。在判断被控侵权产品与外观设计专利产品是否相同或者相近似时，如果从专业人员的角度出发，对权利人来说显然是不公平的。因此，进行外观设计专利侵权判定，应当以营通消费者的审美观察能力为标准，不应当以该外观设计专利所属领域的专业技术人员的审美观察能力为标准,对于类别相同或者相近似的产品，如果普通消费者施以一般注意力不致混淆，则不构成侵权，如果普通消费者施以一般注意力仍不免混淆，则构成侵权。
      </div>

      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;上文中的普通消费者，是指购买、使用该外观设计专利产品的人。通常情况下，普通消费者与消费者权益保护法中"消费者"的涵义是一致的。但是，对于非通常消费品，如建筑材料、机器零部件、电动工具等，曾通消费者不是其购买者，不具有对这类用品的一般知识和认知能力，故能够对其进行相同或相近似比对的主体应当为这类用品的特定消费群体，即销售、购买、安装和使用此类产品的人员。
      </div>

      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;以普通消费者为侵权判定的主体，并不是要求人民法院在审理外观设计专利侵权纠纷时去追求真正的消费者的意见，而是要求审判人员在判断时，将所处的位置放在普通消费者的水平线上，去认识、感知比对对象的异同。
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        比对的方法
      </div>
      <div class="content" style="margin-top: 20px">
        判断外观设计相同或相近似，一般采用以下几种方法:
      </div>

      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;1、肉眼观察。
      </div>

      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;判断被控慢权产品是否与外观设计专利产品相同或相近似，应该根据曾通消费者用肉眼进行观察时是否会产生混淆来判断，对视觉观察不到的部分，不能借助仪器或化学手段进行分析比较。观察时应以产品易见部位的异同作为判断的依据。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;2、隔离观察，直接对比。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;在具体判断时，首先应当把外观设计专利产品与被控侵权产品分别摆放，观察时在时间和空间上均要有一定的间隔
        .这种隔离观察的方法可以让审判人员对两种产品产生直观的感觉即第一印象。其次，再将两种产品摆放在一起，由审判人员对两种产品的外观设计进行直接对比分析，以描述二者的异同，将感性认识上升为理性认识，最终得出二者是否相同或相近似的结论。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;3、整体观察，综合判断。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;判断被控侵权产品的外观设计与获得专利的外观设计是否相同或相近似，不能仅从外观设计的局部出发，或者把外观设计的各部分割裂开来，而应当从其整体出发，对其所有要素进行整体观察，在整体观察的基础上，对两种产品的外观设计的主要构成和创新点进行综合判断。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;被控侵权产品构成侵权须满足两个条件：
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;一是被控侵权产品包含外观设计专利的独创性部分(即创新点)，二是被控侵权产品从整体上与外观设计专利产品相同或相近似。
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        设计要部的确定
      </div>
      <div class="content" style="margin-top: 20px">
        设计要部是外观设计专利中设计人独创的富于美感的主要部分
        ，亦即设计人通过创造性劳动而完成的外观设计专利的创新点。在对被控侵权产品与专利产品的外观设计进行对比时，目前较为认同的做法是将要部作为比较的重点，看被控侵权产品是否抄袭、模仿了权利人的独创部分。要部相同或相近似是构成相同或相近似的外观设计的必要条件，要部不相同和不相近似的外观设计是不相同和不相近似的外观设计，不构成侵权。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;在专利权的申请阶段，大多数申请人未明确指出其要部;在权利的授予阶段，审查人员也只注重外观设计的整体效果;在授权公告中，国务院专利行政部门更不会明确划分出哪些是专利的要部。然而，要部作为构成整体外观的组成部分，虽然较为分散，但通常可以在专利公告的视图中表现出来。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;外观设计要部是产品中最能引起普通购买者和使用者观察和注意的主要视觉部位。实践中，对要部确认的做法不尽一致，有的采用的是由权利人直接陈述的方法，有的采用的是由权利人和被控侵权人陈述、质证后由法院确定的方法。不论采用何种方法，权利人的外观设计中包含有能引起消费者注意的设计创新内容是确定要部的基本原则
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        相同或近似
      </div>
      <div class="content" style="margin-top: 20px">
        设计要部是外观设计专利中设计人独创的富于美感的主要部分
        ，亦即设计人通过创造性劳动而完成的外观设计专利的创新点。在对被控侵权产品与专利产品的外观设计进行对比时，目前较为认同的做法是将要部作为比较的重点，看被控侵权产品是否抄袭、模仿了权利人的独创部分。要部相同或相近似是构成相同或相近似的外观设计的必要条件，要部不相同和不相近似的外观设计是不相同和不相近似的外观设计，不构成侵权。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;在专利权的申请阶段，大多数申请人未明确指出其要部;在权利的授予阶段，审查人员也只注重外观设计的整体效果;在授权公告中，国务院专利行政部门更不会明确划分出哪些是专利的要部。然而，要部作为构成整体外观的组成部分，虽然较为分散，但通常可以在专利公告的视图中表现出来。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;外观设计要部是产品中最能引起普通购买者和使用者观察和注意的主要视觉部位。实践中，对要部确认的做法不尽一致，有的采用的是由权利人直接陈述的方法，有的采用的是由权利人和被控侵权人陈述、质证后由法院确定的方法。不论采用何种方法，权利人的外观设计中包含有能引起消费者注意的设计创新内容是确定要部的基本原则
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        三要素的比对顺序
      </div>
      <div class="content" style="margin-top: 20px">
        依照专利法实施细则第二条第三款的规定，外观设计包括形状外观设计、图案外观设计、形图结合外观设计、形色结合外观设计、图色结合外观设计和形图色结合外观设计。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;构成外观设计的要素有三种，即外观设计专利产品的形状、图案和色彩。在三要素中，形状、图案是基础，色彩是附着在形状、图案之上的，脱离形状和图案的色彩不能单独成为中国现行专利法中外观设计专利保护的设计方案。从这个意义上讲，色彩保护具有从属性。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;有鉴于此，在进行两种产品的外观设计比对时，一般应按照形状、图案、色彩的顺序依次进行。在判断形图色结合的外观设计是否相同或相似时，应当先判断形状是否相同或相似，如果形状不相同或不相似，则可以认定外观设计不相同或不相近似，无须再进行图案和色彩的比对;如果形状相同或相似但该形状属于公知在先设计，应进一步判断图案是否相同或相似，图案不相同或不相似，则可以认定外观设计不相同或不相近似，无须再进行色彩的比对;如果图案相同或相似但该图案属于公知在先设计，再对色彩是否相同、相近似进行判断，色彩不相同也不相近似的不构成相同或相近似的外观设计，色彩相同或相近似的则构成相同或相近似的外观设计
        .在三要素中，形状是最主要的，在侵权判定时应以对比形状为主。如果产品的外观形状是专利权人首创，而被控侵权产品使用了该形状并添加了图案，则无论被控侵权产品添加了何种图案，均应认定为侵权。
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利分类
      </div>
      <div class="content" style="margin-top: 20px">
        根据《专利法实施细则》的规定，申请外观设计专利的，申请人应当写明使用外观设计的产品及其所属类别，未写明使用外观设计的产品所属类别或者所写的类别不确切的，由专利局予以补充或者修改。国家知识产权局《专利审查指南》对外观设计分类的内容、分类的依据、分类号的确定等作了相应的说明。
      </div>
      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        分类的内容
      </div>
      <div class="content" style="margin-top: 20px">
        分类审查员根据国际外观设计分类表进行分类，对申请外观设计专利的产品给出大类和小类号。
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        分类的内容
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;外观设计分类应当以申请人提交的图片或者照片中表示的产品为依据。为保证分类的准确性，申请人应当提供产品类别及其所属领域等信息。记载在请求书中的"使用该外观设计的产品名称"及简要说明中写明的"所属领域、用途、使用方法或使用场所"等信息可以对图片或者照片中表示的产品的类别起解释或限定作用，可以作为分类的依据或参考。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;在请求书中记载的使用该外观设计的产品名称与外观设计图片或者照片中表示的产品不一致的情况下，分类审查员应当根据外观设计图片或者照片给出分类号，并且对此情形进行标注。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;在申请人根据《专利法实施细则》的规定给出外观设计产品的类别的情况下，在确定该产品的类别时一般应当尊重当事人的意愿，但申请人给出的类别不准确或者不符合有关规定的，审查员可以依职权对该类别进行修改，给出准确的分类号;在外观设计专利申请人未给出外观设计产品的类别的情况下，审查员可以依职权给出产品的分类号;在外观设计专利申请审查过程中，由于使用该外观设计的产品名称修改等原因需修改分类号的，应当由审查员重新进行分类。
      </div>

      <div class="c_title" style="margin-top: 20px">
        <span
          style="
            height: 10px;
            width: 10px;
            display: inline-block;
            border-radius: 50%;
            background-color: #000;
          "
        ></span>
        分类号的确定
      </div>
      <div class="content" style="margin-top: 20px">
        在确定一项外观设计产品的类别时，应当根据产品的用途确定其在外观设计分类表中的大类和小类。相同用途的产品应当被分入外观设计分类表中相同的大类和小类。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;一项外观设计产品的类别应当被分到外观设计分类表中的包含该产品用途的上位概念的小类;没有包含该产品用途的上位概念的小类的，应当被分到包含该产品用途的上位概念的大类中的杂项类;没有包含该产品用途的上位概念的大类的，应当被分到分类表的杂项类。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;分类号由大类号和小类号组成。大类号和小类号分别用两位阿拉伯数字表示;不足两位数的，在数字1至9之前加0;大类号和小类号之间用破折号连接。例如，电视机的分类号为14-03。此外，对于多类别产品，多个分类号之间用分号分隔。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;一单一用途产品的分类
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;一项外观设计产品或者成套产品具有单一用途的，给出一个分类号。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;二多用途产品的分类
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;一项外观设计产品为两个或两个以上不同用途的产品的组合体时，审查员可以给出与其用途相应的多个分类号(一般为两个)。以该产品的主要用途所确定的分类号为主分类号，以次要用途确定的分类号为副分类号。一般来说，以体现在产品外观的主要部分或较大部分的用途为主要用途，以体现在产品外观的次要部分或较小部分的用途为次要用途。例如，对于带收音机的闹钟，如果产品外观的主要部分或较大部分的用途为闹钟，产品外观的次要部分或较小部分用途为收音机，则其主分类号为10-01、副分类号为14-01。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;三分类补正通知书
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;申请人未写明产品的所属类别，并且没有在简要说明中写明所属领域、用途、使用方法或使用场所的，或者简要说明中所记载的所属领域、用途、使用方法或使用场所不确切的，审查员可以发出分类补正通知书，要求申请人对该产品的所属领域、用途、使用方法或使用场所作出说明。必要时，还可要求申请人提交该产品的使用状态参考图。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;申请人应当在收到分类补正通知书后一个月内答复，期满未答复或答复后仍不符合规定的，审查员依据申请文件给出分类号。
      </div>
      <div class="content" style="margin-top: 20px">
        &nbsp;&nbsp;&nbsp;&nbsp;
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        外观设计专利侵权判断
      </div>
      <div>
        <img
          style="width: 100%"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-13/1575.png"
          alt=""
        />
      </div>

      <div class="title" style="margin-top: 50px">
        <img src="@/assets/img/propertyRight/4/1.png" alt="" />
        我们的优势
      </div>
      <div>
        <img
          style="width: 100%"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-13/1578.png"
          alt=""
        />
      </div>
    </div>

    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 80px"
    ></bottomWiedt>
  </div>
</template>

<script>
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
export default {
  components: {
    bottomWiedt,
  },
  data() {
    return {
      value: "",
      value1: "",
      bottomTitle: "创业前沿资讯",
    };
  },
  methods: {
    submit() {
      if (this.value1 == "") {
        this.$message("请输入名称！");
        return;
      }

      if (this.value.length != 11) {
        this.$message("请输入正确的手机号");
        return;
      }
      const url = "https://admin.atqf.cn/prod-api/atgw/wgsjzl";
      const options = {
        method: "POST", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
        body: JSON.stringify({
          phonenumber: this.value,
          status: "2",
          name: this.value1,
        }), // 设置请求体
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 200) {
            this.$message("提交成功！");
            this.value = "";
            this.value1 = "";
          } else {
            this.$message(res.msg);
          }
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>
<style scoped>
.contents {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin: 10px 0;
  margin-left: 30px;
}
.c_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  margin: 10px 0;
}
.title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  margin: 20px 0;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin: 10px 0;
  padding-left: 10px;
}
.bg {
  display: flex;
  height: 450px;
  width: 2000px;
  margin: 0 auto;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-13/xwc.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto;
          padding: 20px 0;
        "
      >
        <div style="margin: 0 40px 40px">
          <div class="title">ICP经营许可证服务明细</div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title">ICP经营许可证办理服务流程</div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "1",
      middleList: [
        {
          title: "该怎样办理增值电信业务经营许可证？",
          content: [
            {
              one:
                "信息发布平台和递送服务、信息搜索查询服务、信息社区平台服务、信息即时交互服务、信息保护和处理服务等。",
              two:
                "1、通过电脑端或移动端进行有偿的信息下载的服务，如音乐、视频、图书的付费使用;",
              three:
                "2、通过互联网为用户提供网站设计、图片设计、动画设计、视频设计;",
              four:
                "3、通过互联网进行线上线下交易的不是互联网信息服务，不需要办此许可证:如网上卖衣服、卖电器等实体产品交易。",
              five: "4、信息发布和递送平台，如应用商店;软件下载平台;",
              six: "5、信息搜索服务，如百度搜索，360搜索等:",
              seven: "6、信息社区平台，如QQ、陌陌、YY、视频直播等",
              eight: "7、客户端安全软件，如360杀毒、瑞星杀毒、卡巴斯基等。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "ICP许可证非办不可的理由!",
          content: [
            {
              one: "合法合规",
              two: "享受税收优惠",
              three: "知名网站必备资源",
              four: "企业发展必备资质",
              five: "成为知名网站的必备前提",
            },
          ],
          remerk: "ICP经营许可证办理服务内容",
          icon: true,
          img: "",
          iconImg: "",
        },
        {
          title: "ICP经营许可证办理服务内容",
          content: [
            {
              one: "费用包含:",
              two: "增值电信业务经营许可证",
              three: "专业技术方案提供",
              four: "专业技术人员派遣",
              five: "专业申请网站",
              six: "难点技术攻关",
              seven: "业务指导规划",
            },
          ],
          remerk: "全程无须您亲自到场/双向快递、极速拿照",
          icon: true,
          img: "",
          iconImg: "",
        },
        {
          title: "ICP经营许可证申请需要准备的材料",
          content: [
            {
              one:
                "1、有与开展经营活动相适应的资金，注册资本不少于100万元人民币;",
              two: "2、申请ICP许可证公司提供至少3名以上人员社会保险证明材料;",
              three: "3、有与开展经营活动相适应的技术人员。",
              four: "4、申请ICP许可证公司章程，公司股权结构及股东的有关情况",
              five: "注:部分省份不需要社保，部分省份需技术人员。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "ICP经营许可证申请",
      middleContent:
        "互联网有偿信息服务，包括在线销售、游戏会员、网站广告、付费新闻、网页制作、在线支付、广告招商、会员收费、企业合作、项目投标等需",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  padding: 50px 0;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
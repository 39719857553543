<template>
  <div>
    <div class="bg">
      <div style="padding: 150px 0 0 400px">
        <div
          style="
            font-family: Alimama ShuHeiTi;
            font-weight: bold;
            font-size: 80px;
            color: #EE3D33;
          "
        >
          版权登记
        </div>
        <div
          style="
            margin-top: 50px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 38px;
            color: #EE3D33;
            margin-left: 75px;
          "
        >
          绿色通道
        </div>
      </div>
    </div>

    <div
      style="
        width: 1600px;
        margin: 0 auto;
        padding: 30px;
        display: flex;
        align-items: center;
      "
    >
      <div class="content">
        <div class="c_title">100%</div>
        <div class="ct_title">著作版权100%登记成功</div>
      </div>
      <div class="content">
        <div class="c_title">加急</div>
        <div class="ct_title">绿色通道</div>
      </div>
      <div class="content">
        <div class="c_title">一对一</div>
        <div class="ct_title">一对一贵宾级服务</div>
      </div>
      <div class="content" style="border: 0">
        <div class="c_title">7*24</div>
        <div class="ct_title">7*24小时为您服务</div>
      </div>
    </div>

    <div class="c_bg">
      <div
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
          padding: 40px 0 20px;
          border-bottom: 1px solid #D0D0D0;
          position: relative;
          text-align: center;
        "
      >
        <div
          style="
            height: 5px;
            width: 75px;
            background-color: #F55656;
            position: absolute;
            bottom: -3px;
            left: 965px;
          "
        ></div>
        版权登记快速通道
      </div>

      <div style="width: 1600px; margin: 80px auto 0; padding-left: 100px">
        <div style="display: flex">
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/1.png"
              alt=""
            />
            <div>文字作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/2.png"
              alt=""
            />
            <div>口述作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/3.png"
              alt=""
            />
            <div>音乐作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/4.png"
              alt=""
            />
            <div>戏剧作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/5.png"
              alt=""
            />
            <div>舞蹈作品</div>
          </div>
        </div>

        <div style="display: flex; margin-top: 30px">
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/6.png"
              alt=""
            />
            <div>杂技作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/7.png"
              alt=""
            />
            <div>杂技作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/8.png"
              alt=""
            />
            <div>摄影作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/9.png"
              alt=""
            />
            <div>工程设计图</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/10.png"
              alt=""
            />
            <div>产品设计图</div>
          </div>
        </div>

        <div style="display: flex; margin-top: 30px">
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/11.png"
              alt=""
            />
            <div>地图</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/12.png"
              alt=""
            />
            <div>示意图</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/13.png"
              alt=""
            />
            <div>模型作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/14.png"
              alt=""
            />
            <div>建筑作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/15.png"
              alt=""
            />
            <div>电影作品</div>
          </div>
        </div>

        <div style="display: flex; margin-top: 30px">
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/16.png"
              alt=""
            />
            <div>摄制创作作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/17.png"
              alt=""
            />
            <div>汇编作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/18.png"
              alt=""
            />
            <div>多媒体汇编作品</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/19.png"
              alt=""
            />
            <div>计算机软件</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/20.png"
              alt=""
            />
            <div>著作权查询</div>
          </div>
        </div>

        <div style="display: flex; margin-top: 30px">
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/21.png"
              alt=""
            />
            <div>著作权转让变更</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/22.png"
              alt=""
            />
            <div>著作权撤销</div>
          </div>
          <div class="m_content">
            <img
              style="margin: 18px 0"
              src="@/assets/img/propertyRight/5/23.png"
              alt=""
            />
            <div>著作权补证</div>
          </div>
          <div class="m_contents"></div>
          <div class="m_contents"></div>
        </div>
      </div>
    </div>

    <div class="m_bg">
      <div
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 30px;
          color: #FFFFFF;
          padding: 50px 0;
          text-align: center;
        "
      >
        版权登记的重要性
      </div>
      <div style="width: 1600px; margin: 0 auto; display: flex">
        <div class="bb_content">
          <img
            style="margin: 20px 0"
            src="@/assets/img/propertyRight/5/24.png"
            alt=""
          />
          <div class="bb_title">宣传</div>
          <div class="bb_text">
            通过登记机构的定期公告， 可以向社会宣传自己的产品。
          </div>
        </div>
        <div class="bb_content">
          <img
            style="margin: 20px 0"
            src="@/assets/img/propertyRight/5/24.png"
            alt=""
          />
          <div class="bb_title">保护</div>
          <div class="bb_text">
            在发生著作权争议时，《著作权登记证书》是主张权利的有力武器，同时是向人民法院提起诉讼，请求司法保护的前提。
          </div>
        </div>
        <div class="bb_content">
          <img
            style="margin: 20px 0"
            src="@/assets/img/propertyRight/5/24.png"
            alt=""
          />
          <div class="bb_title">增值</div>
          <div class="bb_text">
            在进行软件版权贸易时，《软件著作权登记证书》作为权利证明，有利于交易的顺利完成。同时，国家权威部门的认证将使您的软件作品价值倍增。
          </div>
        </div>
        <div class="bb_content">
          <img
            style="margin: 20px 0"
            src="@/assets/img/propertyRight/5/24.png"
            alt=""
          />
          <div class="bb_title">荣誉</div>
          <div class="bb_text">
            《著作权证书》是您水平的客观反映，国家权威部门对您能力的认可将使您在求职应聘时更加自信。
          </div>
        </div>
        <div class="bb_content">
          <img
            style="margin: 20px 0"
            src="@/assets/img/propertyRight/5/24.png"
            alt=""
          />
          <div class="bb_title">福利</div>
          <div class="bb_text">
            企业申请是办理双软认定、高新技术企业认定得前提，可以享受国家税收优惠政策。
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1600px; margin: 0 auto">
      <div
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 30px;
          color: #333333;
          text-align: center;
          padding: 80px 0 50px;
        "
      >
        申请版权登记所需提供文件一览
      </div>
      <img
        style="width: 1600px"
        src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-14/a94e93509073526b1f2a68f69b75a1c.png"
        alt=""
      />
    </div>

    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 80px"
    ></bottomWiedt>
  </div>
</template>
<script>
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
export default {
  components: {
    bottomWiedt,
  },
  data() {
    return {
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.bb_text {
  margin: 35px 20px 0;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}
.bb_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
}
.bb_content {
  width: 277px;
  height: 439px;
  border: 1px solid #FFFFFF;
  margin-right: 15px;
  text-align: center;
}
.m_bg {
  margin: 0 auto;
  height: 700px;
  width: 2000px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-14/xsfeggggga.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.m_content div {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.m_content {
  flex: 1;
  margin-right: 100px;
  border: 1px solid #9F9F9F;
  border-radius: 21px;
  background: #FFFFFF;
  width: 179px;
  height: 179px;
  text-align: center;
}
.m_contents {
  flex: 1;
  margin-right: 100px;
  width: 179px;
  height: 179px;
}
.c_bg {
  margin: 0 auto;
  height: 1300px;
  width: 2000px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-14/zuvfg8.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.ct_title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 10px;
}
.c_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #EE3D33;
}
.bg {
  margin: 0 auto;
  height: 470px;
  width: 2000px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-14/banner12cf.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.content {
  text-align: center;
  flex: 1;
  border-right: 1px solid #9F9F9F;
}
</style>
<template>
  <div>
    <el-dialog
      center
      title="请使用微信扫码关注公众号后进行人工客服在线咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div style="text-align: center;">
        <img
          style="height: 350px; width: 350px"
          src="@/assets/img/home/zx.jpg"
          alt=""
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("Visible");
    },
  },
};
</script>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin-bottom: 90px">
          <div class="title" style="margin: 50px 0">
            办理国内多方通信服务业务需要准备的材料
          </div>
          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 35%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  01
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  营业执照副本
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 30%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  02
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  法人及股东身份证
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 35%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  03
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司章程
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 7%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  04
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司主要管理人员及技术人员身份证
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 40%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  05
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  社保证明
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 25%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  06
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  接入协议及托管商资质
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 30%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  07
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  域名注册证书
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 20%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  08
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  法律法规规定的其他材料
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            国内多方通信服务业务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          国内多方通信服务业务办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "3",
      middleList: [
        {
          title: "谁需要办理国内多方通信业务",
          content: [
            {
              one: "开展远程诊断、远程教学等服务。;",
              two: "为其他公司提供多方会议服务。",
              three: "为其他公司提供多方会议服务。",
              four:
                "通过多方通信平台和公用通信网或互联网实现国内两点或多点之间实时交互式或点播式的话音、图像通信服务的企业。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理多方通信资质申请条件",
          content: [
            {
              one: "(一)经营者为依法设立的公司。",
              two: "(二)有与开展经营活动相适应的资金和专业人员。",
              three: "(三)有为用户提供长期服务的信誉或者能力。",
              four: "(四)省网业务的注册资本最低限额为100万元人民币。",
              five: "(五)全网业务的注册资金最低限额为1000万元人民币。",
              six: "(六)有必要的场地、设施及技术方案。",
              seven:
                "(七)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录。",
              eight: "(八)国家规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "国内多方通信服务业务",
      middleContent:
        "国内多方通信服务业务是指通过多方通信平台和公用通信网或互联网实现国内两点或多点之间实时交互式或点播式的话音、图像通信服务。国内多方通信服务业务包括国内多方电话会议服务业务、国内可视电话会议服务业务和国内互联网会议电视及图像服务业务等。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
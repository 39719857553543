<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin-bottom: 90px">
          <div class="title" style="margin: 50px 0">
            办理网络出版许可证需要准备的材料
          </div>
          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  01
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  新闻出版总署统一制发的《互联网出版业务申请表》
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 38%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  02
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  单位的概况
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  03
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  技术和管理人员清单及不少于10人身份证、学历/职称复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 7%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  04
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  至少5名专业编辑人员(含2名或2名以上中级编辑)专业职称证明和身份证明复印件
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  05
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  房产证及有效期不少于一年的租赁合同复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  06
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  申办单位最近两年的验资报告或经审计的财务报告复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  07
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司章程复印件及股权结构及股东的有关情况说明
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  08
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  已取得相关省级主管部门同意的前置审批文件或保证书
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            网络出版许可证服务项目
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          网络出版许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "13",
      middleList: [
        {
          title: "哪些业务需申请网络出版服务许可证",
          content: [
            {
              one:
                "已正式出版的图书、报纸、期刊、音像制品、电子出版物等出版物内容或者在其他媒体上公开发表的作品;",
              two:
                "经过编辑加工的文学、艺术和自然科学、社会科学、工程技术等方面的作品。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请网络出版服务许可证开展业务有何处罚?",
          content: [
            {
              one:
                "未经批准，即没有按时办理网络出版许可证的，擅自从事互联网出版活动的，由省、自治区、直辖市新闻出版行政部门或者新闻出版总署予以取缔，没收从事非法出版活动的主要设备、专用工具及违法所得，违法经营额1万元以上的，并处违法经营额5倍以上10倍以下罚款;违法经营额不足1万元的，并处1万元以上5万元以下罚款。",
            },
          ],
          remerk: "",
          icon: false,
          img: "13",
          iconImg: "",
        },
        {
          title: "办理网络出版许可证需要什么条件",
          content: [
            {
              one:
                "(-)有确定的从事网络出版业务的网站域名智能终端应用程序等出版平台司。",
              two: "(二)有确定的网络出版服务范围。",
              three:
                "(三)有从事网络出版服务所需的必要的技术设备，相关服务器和存储设备必须存在中国境内。",
              four: "(四)有确定的、不与其他出版单位相重复的主体名称及章程。",
              five: "(五)有符合国家规定的法定代表人和主要负责人。",
              six:
                "(六)除法人和主要负责人外，有适应网络出版服务范围需要的专业人员。",
              seven:
                "(七)有固定的工作场所及从事网络出版服务所需的内容审校制度。",
              eight: "(八)法律、行政法规和国家新闻出版广电总局规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "网络出版服务许可证申请",
      middleContent:
        "互联网出版业务，指互联网信息服务提供者将自己创作或他人创作的作品经过选择和编辑加工，登载在互联网上或者通过互联网发送到用户端，供公众浏览、阅读、使用或者下载的在线传播行为。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
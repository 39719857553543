<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin: 50px 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            广播电视节目制作许可证服务
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 50px">
          广播电视节目制作许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "19",
      middleList: [
        {
          title: "广播电视节目制作许可证办理机构",
          content: [
            {
              one: "广播电视总局社会管理司、省级广播影视局",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请广播电视节目制作许可证开展业务有何处罚?",
          content: [
            {
              one:
                "擅自设立广播电视节目制作经营单位或者擅自制作电视剧及其他广播电视节目的，由县级以上人民政府广播电视行政部门予以取缔，没收其从事违法活动的专用工具、设备和节目载体，并处1万元以上5万元以下的罚款。",
            },
          ],
          remerk: "",
          icon: false,
          img: "19",
          iconImg: "",
        },
        {
          title: "广播电视节目制作许可证办理条件",
          content: [
            {
              one:
                "(一)具有独立法人资格的境内社会组织、企事业机构(不含在境内设立的外商独资企业或中外合资、合作企业)，有符合国家法律、法规规定的机构名称、组织机构和章程;",
              two:
                "(二)有适应业务范围需要的广播电视及相关专业人员、资金和工作场所，其中企业注册资金不少于300万元人民币(不用实缴);",
              three:
                "(三)在申请之日前三年，其法定代表人无违法违规记录或机构无被吊销过《广播电视节目制作经营许可证》的记录;",
              four: "(四)法律、行政法规规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "广播电视节目制作许可证办理所需材料",
          content: [
            {
              one: "(一)书面申请，并填写《广播电视节目制作经营许可证申请表》;",
              two: "(二)法定代表人身份证明(复印件)及简历;",
              three: "(三)行政许可授权委托书。",
              four:
                "(四)主要管理人员(不少于三名)的身份证复印件及简历、广播电视及相关专业的资质证明材料,",
              five:
                "(五)法人证书或营业执照副本(有“制作发行动画片、电视综艺、专题片”经营范围)。",
              six: "(六)注册地址证明复印件(包括租赁协议、房产证)。",
              seven: "(七)公司章程复印件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "广播电视节目制作许可证",
      middleContent:
        "设立广播电视节目制作经营机构或从事专题、专栏、综艺、动画片、广播剧、电视剧等广播电视节目的制作和节目版权的交易、代理交易等活动的行为，就需要申请广播电视节目制作经营许可证，简称广播电视节目制作许可证。广播电视节目是由广播电台、电视台和省级以上人民政府广播电视行政部门，批准设立的广播电视节目制作经营单位制作。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
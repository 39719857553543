<template>
  <div style="margin-top: 120px">
    <Nav></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div>
        <div class="bg"></div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          margin: 80px 0 50px;
          justify-content: center;
        "
      >
        <div
          class="btn"
          @click="changeBtn(0)"
          :class="isNum == 0 ? 'isActiveStyle' : ''"
        >
          企业介绍
        </div>
        <div
          class="btn"
          @click="changeBtn(1)"
          :class="isNum == 1 ? 'isActiveStyle' : ''"
        >
          联系我们
        </div>
        <!-- <div
          class="btn"
          @click="changeBtn(2)"
          :class="isNum == 2 ? 'isActiveStyle' : ''"
        >
          帮助中心
        </div> -->
      </div>

      <div v-if="isNum == 0">
        <div style="width: 1600px; margin: 0 auto">
          <div>
            <div style="display: flex">
              <div>
                <img
                  src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/z12.png"
                  alt=""
                />
              </div>
              <div style="margin: 40px 30px 30px 30px">
                <p class="q_content">
                  &nbsp;&nbsp;&nbsp;&nbsp;在信息技术咨询方面，福建省爱听企业服务有限公司助力企业信息化转型，提高管理效率。此外，还提供财务咨询、知识产权服务等多元化服务，全方位保障企业权益。公司还为企业提供市场营销策划和创业空间服务，量身定制营销方案，扩大市场份额，并为创业者提供优质办公环境，助力实现梦想。总之，福建省爱听企业服务有限公司的成立，将推动福建省乃至全国企业的持续发展和创新。
                </p>

                <div
                  style="display: flex; align-items: center; margin-top: 85px"
                >
                  <div style="flex: 1; margin-right: 30px; margin-left: 35px">
                    <div>
                      <img src="@/assets/img/us/2.png" alt="" />
                    </div>
                    <div class="q_name">企业使命</div>
                    <div class="q_IconCon">让老板经验企业更简单</div>
                  </div>
                  <div style="flex: 1; margin-right: 30px">
                    <div>
                      <img src="@/assets/img/us/3.png" alt="" />
                    </div>
                    <div class="q_name">企业愿景</div>
                    <div class="q_IconCon">布局四方，四海一家</div>
                  </div>
                  <div style="flex: 1; margin-right: 30px">
                    <div>
                      <img src="@/assets/img/us/4.png" alt="" />
                    </div>
                    <div class="q_name">企业精神</div>
                    <div class="q_IconCon">让老板经验企业更简单</div>
                  </div>
                  <div style="flex: 1">
                    <div>
                      <img src="@/assets/img/us/5.png" alt="" />
                    </div>
                    <div class="q_name">核心价值观</div>
                    <div class="q_IconCon">帮忙企业合法合规</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin: 300px 0 70px">
          <div class="content_bg" style="position: relative">
            <div style="width: 1600px; margin: 0 auto">
              <div class="topBg" style="position: absolute; top: -200px">
                <div style="display: flex">
                  <div style="flex: 2">
                    <div style="margin: 35px; text-align: left">
                      <div
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: bold;
                          font-size: 30px;
                          color: #FFFFFF;
                          margin-bottom: 30px;
                        "
                      >
                        我们的服务
                      </div>
                      <div
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: 400;
                          font-size: 16px;
                          color: #FFFFFF;
                        "
                      >
                        10余个大业态，20多个子品牌、超过600多种服务项目实现企业全生命周期服务闭环。
                      </div>
                    </div>
                  </div>
                  <div
                    style="
                      flex: 8;
                      background-color: #fff;
                      margin: 15px;
                      height: 520px;
                      border-radius: 10px;
                    "
                  >
                    <div
                      style="
                        margin: 20px;
                        border: 1px solid #e4e4e4;
                        height: 480px;
                      "
                    >
                      <div style="display: flex; justify-content: center">
                        <div class="fw_content">
                          <div style="height: 160px; background-color: #fce2e0">
                            <img
                              src="@/assets/img/us/8.png"
                              alt=""
                              style="margin-top: 20px"
                            />
                            <div
                              style="
                                margin: 15px 0;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                font-size: 18px;
                                color: #333333;
                              "
                            >
                              公司注册
                            </div>
                            <div
                              style="
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                              "
                            >
                              让创业更简单
                            </div>
                          </div>

                          <div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">注册公司</span>
                              <span style="flex: 1">专项审批</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">自贸区注册</span>
                              <span style="flex: 1">吊销转注销</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">变更注册</span>
                              <span style="flex: 1">集团公司</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">代理记账</span>
                              <span style="flex: 1">执照收购</span>
                            </div>
                          </div>
                        </div>

                        <div class="fw_content">
                          <div style="height: 160px; background-color: #fce2e0">
                            <img
                              src="@/assets/img/us/9.png"
                              alt=""
                              style="margin-top: 20px"
                            />
                            <div
                              style="
                                margin: 15px 0;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                font-size: 18px;
                                color: #333333;
                              "
                            >
                              增值电信业务经营许可证
                            </div>
                            <div
                              style="
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                              "
                            >
                              让服务更简单
                            </div>
                          </div>

                          <div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1"
                                >互联网信息服务业务(ICP证)</span
                              >
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1"
                                >移动网信息服务业务(SP证)</span
                              >
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">在线数据处理业务(EDI)</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">呼叫中心业务</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1"
                                >互联网接入服务业务(ISP证)</span
                              >
                            </div>
                          </div>
                        </div>
                        <div class="fw_content">
                          <div style="height: 160px; background-color: #fce2e0">
                            <img
                              src="@/assets/img/us/8.png"
                              alt=""
                              style="margin-top: 20px"
                            />
                            <div
                              style="
                                margin: 15px 0;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                font-size: 18px;
                                color: #333333;
                              "
                            >
                              行业审批
                            </div>
                            <div
                              style="
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                              "
                            >
                              让服务更简单
                            </div>
                          </div>

                          <div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">网络文化经营许可证</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">虚拟货币交易与发行</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">游戏版号/游戏备案</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">游戏著作权</span>
                            </div>
                          </div>
                        </div>
                        <div class="fw_content">
                          <div style="height: 160px; background-color: #fce2e0">
                            <img
                              src="@/assets/img/us/8.png"
                              alt=""
                              style="margin-top: 20px"
                            />
                            <div
                              style="
                                margin: 15px 0;
                                font-family: Source Han Sans CN;
                                font-weight: bold;
                                font-size: 18px;
                                color: #333333;
                              "
                            >
                              知识产权
                            </div>
                            <div
                              style="
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                              "
                            >
                              保障您的合法权益
                            </div>
                          </div>

                          <div>
                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">商标注册</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">计算机软件著作权</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">发明专利</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">作品著作权登记</span>
                            </div>

                            <div
                              style="
                                display: flex;
                                justify-content: center;
                                font-family: Source Han Sans CN;
                                font-weight: 400;
                                font-size: 16px;
                                color: #333333;
                                margin: 35px 0;
                              "
                            >
                              <span style="flex: 1">专利转让</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                padding-top: 450px;
                font-family: Alimama ShuHeiTi;
                font-weight: bold;
                font-size: 52px;
                color: #FFFFFF;
              "
            >
              以企业实际需求出发，为您定制专属资质
            </div>

            <div
              style="
                width: 500px;
                height: 46px;
                background-color: #fff;
                text-align: center;
                line-height: 46px;
                border-radius: 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #F55655;
                margin: 60px auto;
                cursor: pointer;
              "
              @click="dialogVisible = true"
            >
              立即咨询、即刻下证
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 0 auto">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
              margin-bottom: 30px;
            "
          >
            服务优势
          </div>
          <div style="display: flex; align-items: center; height: 400px">
            <div style="flex: 1; margin-right: 15px">
              <div style="height: 200px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/us/12.png"
                  alt=""
                />
              </div>
              <el-card style="height: 200px; width: 100%">
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333333;
                    text-align: center;
                    padding: 35px 0 20px 0;
                  "
                >
                  办事效率-高效
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666666;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  一站式办理所有审批签宇 手续，快速办理业务
                </div>
              </el-card>
            </div>
            <div style="flex: 1; margin-right: 15px">
              <div style="height: 200px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/us/13.png"
                  alt=""
                />
              </div>
              <el-card style="height: 200px; width: 100%">
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333;
                    text-align: center;
                    padding: 35px 0 20px 0;
                  "
                >
                  节点反馈-及时
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  信息化流程管控，节点全反馈，我们承诺信息不出错
                </div>
              </el-card>
            </div>
            <div style="flex: 1; margin-right: 15px">
              <div style="height: 200px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/us/14.png"
                  alt=""
                />
              </div>
              <el-card style="height: 200px; width: 100%">
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333;
                    text-align: center;
                    padding: 35px 0 20px 0;
                  "
                >
                  流程标准-正规
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  严格按照流程办理，不伪造客户签字，不泄露客户信息
                </div>
              </el-card>
            </div>
            <div style="flex: 1; margin-right: 15px">
              <div style="height: 200px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/us/15.png"
                  alt=""
                />
              </div>
              <el-card style="height: 200px; width: 100%">
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333;
                    text-align: center;
                    padding: 35px 0 20px 0;
                  "
                >
                  价格体系-透明
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  价格透明，无隐形消费 办理中途零增项
                </div>
              </el-card>
            </div>
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 200px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/us/16.png"
                  alt=""
                />
              </div>
              <el-card style="height: 200px; width: 100%">
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333;
                    text-align: center;
                    padding: 35px 0 20px 0;
                  "
                >
                  监管体系-严格
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #666;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  多重监管部门，保障服务合规性，确保客户利益
                </div>
              </el-card>
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 70px auto">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
              margin-bottom: 30px;
            "
          >
            办公环境
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              height: 500px;
              width: 1600px;
            "
          >
            <div style="flex: 9">
              <div style="display: flex; align-items: center; height: 245px">
                <div>
                  <img
                    style="width: 380px; height: 245px"
                    src="@/assets/img/us/17.png"
                    alt=""
                  />
                </div>
                <div style="margin: 0 20px">
                  <img
                    style="width: 780px; height: 245px"
                    src="@/assets/img/us/18.png"
                    alt=""
                  />
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  height: 245px;
                  margin-top: 20px;
                "
              >
                <div>
                  <img
                    style="width: 780px; height: 245px"
                    src="@/assets/img/us/19.png"
                    alt=""
                  />
                </div>
                <div style="margin: 0 20px">
                  <img
                    style="width: 380px; height: 245px"
                    src="@/assets/img/us/20.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div style="flex: 3">
              <img src="@/assets/img/us/21.png" alt="" />
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 90px auto">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
              margin-bottom: 30px;
            "
          >
            合作企业
          </div>
          <div style="text-align: center">
            <img
              style="height: 400px"
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/22.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div v-if="isNum == 1">
        <div style="width: 1600px; margin: 70px auto">
          <img
            style="height: 600px; width: 100%"
            src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/23.jpg"
            alt=""
          />

          <div
            style="
              padding: 35px 0;
              border-bottom: 1px solid #9F9F9F;
              border-top: 1px solid #9F9F9F;
              margin-top: 35px;
            "
          >
            <div class="other">地址</div>
            <div class="nextContent">
              <div>{{ obj.address }}</div>
              <div style="margin-left: auto">
                <i
                  class="el-icon-location"
                  style="color: #bf1d14; font-size: 35px"
                ></i>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center; padding: 35px 0">
            <div style="flex: 1">
              <div class="other">电话：</div>
              <div class="nextContent">{{ obj.phonenumber }}</div>
            </div>
            <div style="flex: 1">
              <div class="other">邮箱</div>
              <div class="nextContent">{{ obj.email }}</div>
            </div>

            <div style="flex: 1; display: flex">
              <div style="margin-right: 30px; margin-left: auto">
                <div><img :src="obj.swWx" alt="" /></div>
                <div class="other" style="margin-left: 20px">商务微信</div>
              </div>
              <div style="text-align: center">
                <div><img :src="obj.qtWx" alt="" /></div>
                <div class="other">其他微信</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
import Dialog from "@/components/dialog";

export default {
  components: {
    Footer,
    Nav,
    Dialog
  },
  data() {
    return {
      isNum: null,
      obj: null,
      dialogVisible: false,
    };
  },
  created() {
    this.$store.dispatch("fetchCommonData");
    this.obj = this.$store.getters.getCommonData;
    console.log(this.obj, "====");
  },

  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.isNum = now.params.id;
      },
      immediate: true, //立即执行
    },
  },

  methods: {
    changeBtn(val) {
      this.isNum = val;
      console.log(this.isNum, "-====");
    },
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.nextContent {
  display: flex;
  align-items: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
  margin-top: 20px;
}
.other {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.fw_content {
  flex: 1;
  border: 1px solid #e4e4e4;
  height: 480px;
}
.q_IconCon {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.q_name {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 30px 0;
}
.q_content {
  margin-bottom: 8px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.isActiveStyle {
  background-color: #ee3d33 !important;
  color: #fff !important;
}
.btn {
  cursor: pointer;
  margin-right: 25px;
  padding: 8px 25px;
  background-color: #f0f0f0;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.topBg {
  text-align: center;
  height: 550px;
  width: 1600px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/topBg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.content_bg {
  text-align: center;
  height: 700px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (1).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg {
  text-align: center;
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (3).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
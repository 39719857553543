<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 0 20px 50px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          游戏版号办理的必要性
        </div>
        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          全名：游戏版权号或ISBN号 俗称：电子出版物号
        </div>
        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          标准定义
        </div>

        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >是国家新闻出版广播电影电视总局批准
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >相关游戏出版运营的批文号的简称
        </div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          审批机构
        </div>

        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >先省新闻出版局审核后，移交新闻出版
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >总署数字科技司审批
        </div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          强制规定
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >版号相当于游戏的出生证明，是保护开
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >发者著作权的法律文件，也是国家允许
        </div>
        <div class="box_contents">
          <i class="el-icon-caret-right" style="font-size: #000"></i
          >游戏出版上线运营的批准文件
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 50px;
          "
        >
          <div class="title"></div>
          为什么要申办游戏版号？
        </div>

        <div style="display: flex; align-items: center; margin-top: 30px">
          <div
            style="
              padding: 10px 30px;
              background-color: #f3cac7;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              margin-right: 50px;
            "
          >
            游戏发行上线必备条件
          </div>
          <div
            style="
              padding: 10px 30px;
              background-color: #f3cac7;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              margin-right: 50px;
            "
          >
            持续经营合法合规
          </div>
          <div
            style="
              padding: 10px 30px;
              background-color: #f3cac7;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              margin-right: 50px;
            "
          >
            各应用商店的审核条件
          </div>
          <div
            style="
              padding: 10px 30px;
              background-color: #f3cac7;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
              margin-right: 50px;
            "
          >
            著作权维护防止盗版
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 50px;
          "
        >
          <div class="title"></div>
          怎么申办游戏版号？
        </div>
        <div class="box_contents">
          注：因办理地区不同，办理条件和申请资料也会有所不同，具体可以咨询顾问
        </div>
        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          办理条件
        </div>

        <div class="box_contents">· 游戏开发或者运营公司取得软件著作权</div>
        <div class="box_contents">· 有通信管理局颁发的互联网经营许可证</div>
        <div class="box_contents">· 国家规定的其他条件</div>

        <div
          class="box_contents"
          style="font-size: 18px; font-weight: bold; color: #333333"
        >
          办理条件
        </div>
        <div class="box_contents">· 游戏说明书、测试帐号及密码、游戏脚本</div>
        <div class="box_contents">· 游戏评测报告、游戏演示光盘</div>
        <div class="box_contents">· 防沉迷系统介绍以及相关证明文件</div>
        <div class="box_contents">· 企业法人身份证原件、营业执照复印件</div>
        <div class="box_contents">· 计算机软件著作权登记证书、ICP许可证</div>
        <div class="box_contents">· 游戏屏蔽字库及其他相关证明材料</div>
      </div>

      <div style="margin-bottom: 100px; margin-top: 50px">
        <div style="margin: 0 40px 40px">
          <div
            style="
              margin-bottom: 40px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
            "
          >
            游戏版号申请服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            margin-bottom: 50px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 30px;
            color: #333333;
            text-align: center;
            margin-top: 50px;
          "
        >
          游戏版号申请服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "33",
      middleList: [],
      middleTitle: "游戏版号申请",
      middleContent:
        "游戏版号是游戏版权号的简称，是由国家新闻出版广电总局审核发布的对于游戏根据[著作权（游戏软件著作权，含网络游戏）法]和[计算机游戏软件保护条例]等法律法规的规定，游戏软件厂商或者个人作者开发的游戏软件在开发完成后就受到著作权（游戏软件著作权，含网络游戏）法的保护。游戏版号（也就是游戏出版备案）俗称电子出版物号，简称ISBN，普遍的有光盘发行号，书号。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.box_contents {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  margin: 15px 0;
}
.box_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.box_content {
  flex: 1;
  border: 1px solid #D2D2D2;
  padding: 10px;
  margin-right: 15px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <Nav></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div style="padding-top: 110px;">
        <div class="bg"></div>
      </div>

      <div style="width: 1600px; margin: 50px auto 100px; height: 1000px">
        <div style="display: flex">
          <div style="flex: 7; margin-right: 30px">
            <div class="content">
              <div class="nameStyle">呼叫中心变更申请指南</div>
              <div class="time">
                <span>发布时间：2024-06-09 13:49:56 </span>
                <span style="margin: 0 35px">作者：爱听企服</span>
                <span>阅读：421</span>
              </div>
              <div>
                <p class="htmlStyle">
                  公司注册资本100万，年限到了没缴怎么办?发布时间:2021-06-0913:49:56
                  作者:新鑫欣
                  阅读:421以前在注册公司的时候都必须是实缴，比如你注册资本100万，那你必须通过100万的验资才可以正常开公司;而现在实行认缴制，注册资本多少需要股东自行协商，你认缴100万也可以认缴，一个亿也可以。当然在认缴制之下，在认缴期限没有到期之前，你可以操作的空间还是很大的，比如你目前注册资本是100万，认缴期限3年，在认缴到期之前你可以采取以下几种方式。
                </p>
                <div>1、申请延期</div>
                <p class="htmlStyle">
                  目前公司的注册期限一般都是比较长，有很多公司都是没有期限限制的，而认缴期限则是由股东在章程里面自行约定，如果在事先约定的认缴期限到期之后没有能力实缴，那可以召开股东大会更改公司章程，对认缴期限进行更改，比如从三年更改到5年或者10年等等。
                </p>
                <div>2、注销公司</div>
                <p class="htmlStyle">
                  目前公司的注册期限一般都是比较长，有很多公司都是没有期限限制的，而认缴期限则是由股东在章程里面自行约定，如果在事先约定的认缴期限到期之后没有能力实缴，那可以召开股东大会更改公司章程，对认缴期限进行更改，比如从三年更改到5年或者10年等等。
                </p>
                <div>2、注销公司</div>
                <p class="htmlStyle">
                  如果公司注册资本认缴期限还没到期，大家也可以选择注销公司，注销公司的时候是不用实交资本的，但需要绺纳0.05%的印花税，相当于100万需要缴纳500块钱的税，这个费用其实并不是很高，很多人都能够承受得起。
                </p>
                <div>2、注销公司</div>
                <p class="htmlStyle">
                  如果公司注册资本认缴期限还没到期，大家也可以选择注销公司，注销公司的时候是不用实交资本的，但需要绺纳0.05%的印花税，相当于100万需要缴纳500块钱的税，这个费用其实并不是很高，很多人都能够承受得起。
                </p>
                <div>2、注销公司</div>
                <p class="htmlStyle">
                  如果公司注册资本认缴期限还没到期，大家也可以选择注销公司，注销公司的时候是不用实交资本的，但需要绺纳0.05%的印花税，相当于100万需要缴纳500块钱的税，这个费用其实并不是很高，很多人都能够承受得起。
                </p>
              </div>

              <div
                style="margin: 70px 0 50px; display: flex; align-items: center"
              >
                <div
                  style="height: 1px; width: 40%; background-color: #ccc"
                ></div>
                <div
                  style="
                    height: 80px;
                    width: 80px;
                    border-radius: 50%;
                    line-height: 80px;
                    text-align: center;
                    background-color: #ee3d33;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 0 4%;
                    border: 13px solid #fef1f1;
                  "
                >
                  列表
                </div>
                <div
                  style="height: 1px; width: 40%; background-color: #ccc"
                ></div>
              </div>

              <!-- <div
                style="
                  display: flex;
                  align-items: center;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666;
                "
              >
                <div>上一页</div>
                <div style="margin-left: auto">下一页</div>
              </div> -->

              <!-- <div
                style="
                  display: flex;
                  align-items: center;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 14px;
                  color: #666666;
                  margin-top: 10px;
                "
              >
                <div>合伙制改造一过程复杂、 结果简单的事</div>
                <div style="margin-left: auto">
                  合伙制改造一过程复杂、 结果简单的事
                </div>
              </div> -->
            </div>
          </div>
          <div style="flex: 2; position: relative" class="rightContent">
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                padding: 15px;
                border-bottom: 1px solid #D5D5D5;
                position: relative;
              "
            >
              推荐新闻
              <div
                style="
                  height: 3px;
                  width: 75px;
                  background-color: #ee3d33;
                  position: absolute;
                  bottom: 0;
                "
              ></div>
            </div>

            <div class="rightCon" v-for="(item, index) in list" :key="index">
              <div style="display: flex; padding: 10px">
                <div style="margin-right: 20px">
                  <img
                    style="height: 70px; width: 105px"
                    src="@/assets/img/classRoom/5.png"
                    alt=""
                  />
                </div>
                <div>
                  <div
                    class="rightTitle"
                    v-if="item.title.length > 10"
                    style="margin: 10px 0 15px"
                  >
                    {{ item.title.slice(0, 10) }}...
                  </div>
                  <div class="rightTitle" v-else style="margin: 10px 0 15px">
                    {{ item.title }}
                  </div>
                  <div
                    class="rightTitle"
                    style="display: flex; align-items: center"
                  >
                    <span>{{ item.time }}</span>
                    <i class="el-icon-right" style="margin-left: auto"></i>
                  </div>
                </div>
              </div>
            </div>

            <img
              style="width: 100%; position: absolute; bottom: 0"
              src="@/assets/img/classRoom/4.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      isNum: null,
      list: [
        {
          title: "增值电信业务经营许可证",
          img: "../assets/img/classRoom/1.png",
          content:
            "增值电信业务经营许可证允许持证者开展的业务包括电子邮件、语音信箱、在线信息库存储和检索、电子数据交换、在线数据处理与交易处理、增值传真、互联网接入服务、互联网信息服务以及可视电话会议服务等。这些业务都是基于基础电信业务提供的附加服务，能够增加原有基础网络的经济效益或功能价值。",
          time: "2024.6.14",
          new: "游戏行业解决方案",
        },
        {
          title: "增值电信业务经营许可证",
          img: "../assets/img/classRoom/1.png",
          content:
            "增值电信业务经营许可证允许持证者开展的业务包括电子邮件、语音信箱、在线信息库存储和检索、电子数据交换、在线数据处理与交易处理、增值传真、互联网接入服务、互联网信息服务以及可视电话会议服务等。这些业务都是基于基础电信业务提供的附加服务，能够增加原有基础网络的经济效益或功能价值。",
          time: "2024.6.14",
          new: "游戏行业解决方案",
        },
        {
          title: "增值电信业务经营许可证",
          img: "../assets/img/classRoom/1.png",
          content:
            "增值电信业务经营许可证允许持证者开展的业务包括电子邮件、语音信箱、在线信息库存储和检索、电子数据交换、在线数据处理与交易处理、增值传真、互联网接入服务、互联网信息服务以及可视电话会议服务等。这些业务都是基于基础电信业务提供的附加服务，能够增加原有基础网络的经济效益或功能价值。",
          time: "2024.6.14",
          new: "游戏行业解决方案",
        },
        {
          title: "增值电信业务经营许可证",
          img: "../assets/img/classRoom/1.png",
          content:
            "增值电信业务经营许可证允许持证者开展的业务包括电子邮件、语音信箱、在线信息库存储和检索、电子数据交换、在线数据处理与交易处理、增值传真、互联网接入服务、互联网信息服务以及可视电话会议服务等。这些业务都是基于基础电信业务提供的附加服务，能够增加原有基础网络的经济效益或功能价值。",
          time: "2024.6.14",
          new: "游戏行业解决方案",
        },
        {
          title: "增值电信业务经营许可证",
          img: "../assets/img/classRoom/1.png",
          content:
            "增值电信业务经营许可证允许持证者开展的业务包括电子邮件、语音信箱、在线信息库存储和检索、电子数据交换、在线数据处理与交易处理、增值传真、互联网接入服务、互联网信息服务以及可视电话会议服务等。这些业务都是基于基础电信业务提供的附加服务，能够增加原有基础网络的经济效益或功能价值。",
          time: "2024.6.14",
          new: "游戏行业解决方案",
        },
      ],
    };
  },
  created() {
    this.isNum = this.$route.params.id;
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.isNum = now.params.id;
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    readBtn() {
      this.$router.push("/newRead");
    },
    changeBtn(val) {
      this.isNum = val;
    },
  },
};
</script>
<style scoped>
.htmlStyle {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  margin-bottom: 30px;
}
.time {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #ccc;
}
.content {
  border: 1px solid #D5D5D5;
  padding: 30px 70px;
}
.nameStyle {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
}
.rightTitle {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.rightCon {
  height: 90px;
  border-bottom: 1px solid #D5D5D5;
}
.rightContent {
  border: 1px solid #D5D5D5;
  margin-left: 30px;
}
.bg {
  text-align: center;
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (2).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>
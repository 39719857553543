<template>
  <div style="min-height: 2000px">
    <mNav></mNav>
    <div style="margin-top: 50px;">
      <el-carousel class="banner">
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img class="bannerImg" :src="item.img" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="contentTitle">产品与服务</div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div class="borderStyle"></div>
      <div class="PRODUCT">PRODUCT AND SERVICE</div>
      <div class="borderStyle"></div>
    </div>
    <div class="nin">您只需要提出需求，其他的一切放心交给我们</div>

    <div class="bg">
      <el-tabs v-model="activeName" style="margin: 10px" class="tabColor">
        <el-tab-pane label="公司注册" name="first">
          <div class="gszcBg">
            <div style="margin: 0 15px; padding: 20px 0">
              <div style="display: flex; align-items: center">
                <img class="g_icon" src="@/assets/img/home/c_4.png" alt="" />
                <span class="g_title">公司注册</span>
              </div>

              <div style="display: flex; align-items: center; margin-top: 15px">
                <div class="g_zc">注册公司+注册地址+三证五章全套</div>
                <div class="ckxq">查看详情 ></div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 15px 0">
                <div style="display: flex; align-items: center">
                  <img class="g_icon" src="@/assets/img/home/c_5.png" alt="" />
                  <span class="g_title">公司起名/核名</span>
                </div>

                <div
                  style="display: flex; align-items: center; margin-top: 15px"
                >
                  <div class="g_zc">给公司起个名字</div>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 15px 0">
                <div style="display: flex; align-items: center">
                  <img class="g_icon" src="@/assets/img/home/c_6.png" alt="" />
                  <span class="g_title">会计代理</span>
                </div>

                <div
                  style="display: flex; align-items: center; margin-top: 15px"
                >
                  <div class="g_zc">我要代理记账￥200/月</div>
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 15px 0">
                <div style="display: flex; align-items: center">
                  <img class="g_icon" src="@/assets/img/home/c_5.png" alt="" />
                  <span class="g_title">公司变更</span>
                </div>

                <div
                  style="display: flex; align-items: center; margin-top: 15px"
                >
                  <div class="g_zc">变更公司信息￥700/起</div>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 15px 0">
                <div style="display: flex; align-items: center">
                  <img class="g_icon" src="@/assets/img/home/c_6.png" alt="" />
                  <span class="g_title">公司起名/核名</span>
                </div>

                <div
                  style="display: flex; align-items: center; margin-top: 15px"
                >
                  <div class="g_zc">给公司做年报￥100</div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资质办理" name="second">
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">增值电信业务经营许可证</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">营业性演出许可证</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">互联网出版</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">互联网医药</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">影视审批</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">网络文化/网络游戏/网站备案</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="行业审批" name="third">
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">网络文化经营许可证</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">虚拟货币交易与发行</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">游戏版号/游戏备案 </span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">游戏著作权</span>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="知识产权" name="fourth">
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">商标注册</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">计算机软件著作权</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">发明专利</span>
                </div>
              </div>
            </div>
            <div style="flex: 1; background-color: #fff" class="gs_content">
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">作品著作权登记</span>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <div
              style="flex: 1; background-color: #fff; margin-right: 10px"
              class="gs_content"
            >
              <div style="margin: 0 15px; padding: 25px 0">
                <div style="display: flex">
                  <span class="black_icon"></span>
                  <span class="g_titles">专利转让</span>
                </div>
              </div>
            </div>
            <div style="flex: 1" class="gs_content"></div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <div class="contentTitle">实用工具</div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div class="borderStyle"></div>
      <div class="PRODUCT">PRACTICAL TOOLS</div>
      <div class="borderStyle"></div>
    </div>
    <div class="nin">让创业更简单 让服务更专业</div>

    <div>
      <div style="display: flex; padding: 0 10px">
        <div style="flex: 1; margin-right: 10px">
          <div>
            <img
              style="width: 100%"
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-02/h_4.png"
              alt=""
            />
            <div class="gs_bgColor">
              <div class="gszc_t">公司注册</div>
              <div class="gszc_c">注册公司+注册地址+ 三证五章全套</div>
            </div>
          </div>
        </div>
        <div style="flex: 1">
          <img
            style="width: 100%"
            src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-02/h_5.png"
            alt=""
          />
          <div class="gs_bgColor">
            <div class="gszc_t">商标查询</div>
            <div class="gszc_c">
              商标信息变更，商标使用， 为你轻松开启创业之路
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex; padding: 0 10px; margin-top: 10px">
        <div style="flex: 1; margin-right: 10px">
          <div>
            <img
              style="width: 100%"
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-04/h_6.png"
              alt=""
            />
            <div class="gs_bgColor">
              <div class="gszc_t">软著查询</div>
              <div class="gszc_c">
                更深入全面的了解企业，为企业之间合作提供全力保障
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1">
          <img
            style="width: 100%"
            src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-04/h_7.png"
            alt=""
          />
          <div class="gs_bgColor">
            <div class="gszc_t">增值许可证查询</div>
            <div class="gszc_c">增值电信业务许可证获得企业详情</div>
          </div>
        </div>
      </div>

      <div class="ljxq" @click="dialogVisible = true">了解详情</div>
    </div>

    <div class="contentTitle" style="margin-top: 50px">前沿咨询</div>
    <div style="display: flex; align-items: center; justify-content: center">
      <div class="borderStyle"></div>
      <div class="PRODUCT">FRONTIER CONSULTING</div>
      <div class="borderStyle"></div>
    </div>
    <div class="nin">让创业更简单 让服务更专业</div>

    <div>
      <div style="display: flex; height: 50px; line-height: 50px">
        <div
          class="zcfg"
          @click="tabBtn(0)"
          :class="c_isActive == 0 ? 'c_isActiveStyle' : ''"
        >
          政策法规
        </div>

        <div
          class="zcfg"
          @click="tabBtn(1)"
          :class="c_isActive == 1 ? 'c_isActiveStyle' : ''"
        >
          行业知识
        </div>
      </div>
    </div>

    <div style="width: 100%; margin: 0 auto; cursor: pointer;background-color: #f6f6f6;">
      <div style="padding: 10px;">
        <div>
          <div @click="goNext">
            <div
              class="zc_Content"
              v-for="(item, index) in list"
              :key="index"
            >
              <div>{{ item.title }}</div>
              <span style="margin-left: auto"
                >{{ item.createTime.slice(0, 4) }} /
                {{ item.createTime.slice(5, 7) }} /
                {{ item.createTime.slice(8, 10) }}</span
              >
            </div>
          </div>
          <div class="zc_Content" @click="handleClick()">
            MORE
            <i
              style="margin-left: 20px; font-weight: bold; font-size: 16px"
              class="el-icon-right"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mNav from "@/components/mNav";
export default {
  components: {
    mNav,
  },
  data() {
    return {
      activeName: "first",
      bannerList: [],
      picList: [],
      isActiveNum: 0,
      c_isActive: 0,
      kj_active: null,
      scrollThreshold: 200,
      internalActiveName: "1",
      dialogVisible: false,
      isNine: 0,

      pageNum: 1,
      pageSize: 7,
      total: 0,
      list: [],
    };
  },
  created() {
    this.getBannerList();
    this.getPolicylist();
  },
  methods: {
    goNext() {
      this.$router.push("/newRead");
    },
    tabBtn(val) {
      this.c_isActive = val;
      this.getPolicylist();
    },
    getPolicylist() {
      const url = `https://admin.atqf.cn/prod-api/atgw/zskt/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}&type=${this.c_isActive}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
          console.log(this.list, "===========");
        })
        .catch((error) => console.error("Error:", error));
    },
    // 获取banner
    getBannerList() {
      const url = "https://admin.atqf.cn/prod-api/atgw/banner/list";
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          this.bannerList = data.rows;
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>

<style scoped>
::v-deep .tabColor .el-tabs__item{
  color: #fff !important;
}
/* 手机端 */
@media screen and (max-width: 767px) {
  /* 样式规则 */
  .zc_Content {
    height: 35px;
    line-height: 35px;
    display: flex;
    align-items: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 12px;
    color: #333333;
  }
  .zc_Content span {
    margin-left: auto;
    font-size: 12px;
  }
  .zcfg {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    margin: 0 20px;
    cursor: pointer;
  }
  .c_isActiveStyle {
    border-bottom: 3px solid #E7242A;
  }
  .ljxq {
    border: 1px solid #E7242A;
    width: 200px;
    text-align: center;
    padding: 8px 0;
    color: #E7242A;
    font-weight: bold;
    border-radius: 30px;
    font-size: 14px;
    margin: 30px auto 0;
    cursor: pointer;
  }
  .gszc_c {
    margin: 5px 10px;
    font-family: Source Han Sans CN;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
    height: 48px;
  }
  .gszc_t {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    text-align: center;
  }
  .gs_bgColor {
    padding: 10px;
    margin-top: -7px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: linear-gradient(to right, #f3a15f, #e7252a);
  }
  .g_titles {
    height: 38px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
  }
  .black_icon {
    width: 10px;
    height: 10px;
    background-color: #000;
    margin-right: 10px;
    margin-top: 4px;
  }
  .gs_content {
    border-radius: 10px;
  }
  .ckxq {
    margin-left: auto;
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 10px;
  }
  .g_zc {
    font-family: Source Han Sans CN;
    font-weight: 400;
    font-size: 10px;
    color: #666666;
  }
  .g_icon {
    height: 30px;
    width: 30px;
    margin-right: 5px;
  }
  .g_title {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
  }
  .gszcBg {
    background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-02/h_2.png");
    background-size: cover;
    width: 100%;
    height: 100px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 10px;
  }
  .bg {
    display: flex;
    flex-direction: column;
    background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-08-02/M_1.png");
    background-size: cover;
    width: 100%;
    height: 380px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .nin {
    margin: 5px 0 20px 0;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 10px;
    color: #ADADAD;
  }
  .PRODUCT {
    margin: 0 10px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ADADAD;
    font-size: 20px;
  }
  .borderStyle {
    height: 2px;
    background-color: #ec3639;
    width: 25px;
  }
  .banner {
    height: 220px;
  }
  .bannerImg {
    height: 220px;
    width: 100%;
  }
  ::v-deep .el-carousel__item {
    height: 220px !important;
  }
  ::v-deep .el-carousel__container {
    height: 220px !important;
  }
  .contentTitle {
    margin-top: 25px;
    text-align: center;
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
  }
}

/* 平板端 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* 样式规则 */
  .banner {
    height: 400px;
  }
  .bannerImg {
    height: 400px;
    width: 100%;
  }
  ::v-deep .el-carousel__item {
    height: 400px !important;
  }
  ::v-deep .el-carousel__container {
    height: 400px !important;
  }
}
::v-deep .el-tabs__nav-wrap::after {
  display: none !important;
}

::v-deep .el-tabs__active-bar {
  background-color: #fff !important;
}

</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="margin-bottom: 90px">
        <div class="title" style="margin: 50px 0">
          办理国内多方通信服务业务需要准备的材料
        </div>
        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 10%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                01
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司法定代表人签署的经营增值电信业务的书面申请。内容包括;申请电信业务的种类、业务覆盖范围、公司名称、公司通信地址、邮政编码、联系人、联系电话、电子信箱地址等
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 6%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                02
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司的企业法人营业执照副本及复印件
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                03
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司概况。包括公司基本情况，拟从事增值电信业务的人员、场地和设施等情况
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                04
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司最近经会计师事务所审计的企、法人年度财务会计报告或验资报告及电信主管部门规定的其他相关会计资料
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                05
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司章程，公司股权结构及股东的有关情况
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                06
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请经营电信业务的业务发展、实施计划和技术方案
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                07
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                为用户提供长期服务和质量保障的措施
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 29%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                08
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                信息安全保障措施
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 20%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                09
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                证明公司信誉的有关材料;
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                10
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司法定代表人签署的公司依法经营电信业务的承诺书
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                11
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请经营的电信业务依照法律、行政法规及国家有关规定须经有关主管部门事先审核同意的，应当提交有关主管部门审核同意的文件
              </div>
            </div>
          </div>

          <div style="flex: 1"></div>
        </div>
      </div>

      <div style="margin: 0 40px 40px">
        <div class="title" style="margin-bottom: 40px">存储转发类业务明细</div>
        <div
          style="background-color: #fff; display: flex; justify-content: center"
        >
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/2.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              申请
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/3.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              变更/增项
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/4.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              续期/注销
            </div>
          </div>
          <div class="ICP_content">
            <div
              style="
                background-color: #f4f4f4;
                width: 130px;
                height: 130px;
                margin: 0 auto;
              "
            >
              <img
                style="margin-top: 25px"
                src="@/assets/img/zzbl/one/5.png"
                alt=""
              />
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 18px;
                color: #333333;
                margin: 20px 0;
              "
            >
              转让
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          存储转发类业务办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "8",
      middleList: [
        {
          title: "谁需要办理存储转发类业务",
          content: [
            {
              one: "语音信箱",
              two: "电子邮件",
              three: "传真存储转发",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "语音信箱业务",
          content: [
            {
              one:
                "语音信箱业务是指利用与公用通信网、公用数据传送网、互联网相连接的语音信箱系统向用户提供存储、提取、调用话音留言及其辅助功能的一种业务。每个语音信箱有一个专用信箱号码，用户可以通过电话或计算机等终端设备进行操作，完成信息投递、接收、存储、删除、转发、通知等功能。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "电子邮件业务",
          content: [
            {
              one:
                "电子邮件业务是指通过互联网采用各种电子邮件传输协议为用户提供一对一或一对多点的电子邮件编辑、发送、传输、存储、转发、接收的电子信箱业务。它通过智能终端、计算机等与公用通信网结合，利用存储转发方式为用户提供多种类型的信息交换。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "传真存储转发业务",
          content: [
            {
              one:
                "传真存储转发业务是指在用户的传真机与传真机、传真机与计算机之间设立存储转发系统，用户间的传真经存储转发系统的控制，非实时地传送到对端的业务。",
              two:
                "传真存储转发系统主要由传真工作站和传真存储转发信箱组成，两者之间通过分组网、数字专线、互联网连接。传真存储转发业务主要有多址投送、定时投送、传真信箱、指定接收人通信、报文存档及其他辅助功能等。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理存储转发类业务申请条件",
          content: [
            {
              one: "(一)经营者为依法设立的公司。",
              two: "(二)有与开展经营活动相适应的资金和专业人员。",
              three: "(三)有为用户提供长期服务的信誉或者能力。",
              four: "(四)省网业务的注册资本最低限额为100万元人民币。",
              five: "(五)全网业务的注册资金最低限额为1000万元人民币。",
              six: "(六)有必要的场地、设施及技术方案。",
              seven:
                "(七)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录",
              eight: "(八)国家规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "存储转发类业务",
      middleContent:
        " 存储转发类业务是指利用存储转发机制为用户提供信息发送的业务。存储转发类业务包括语音信箱、电子邮件、传真存储转发等业务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="background-color: #f4f4f4; padding-bottom: 30px">
          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              01
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              具有入驻平台企业、购货方、医疗器械的合法性、有效性审核控制功能
            </span>
          </div>

          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              02
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              具有网上订购、支付结算、订单流转、交易状态查询、
              评价投诉、退换产品等交易管理功能
            </span>
          </div>

          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              03
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              具有医疗器械记录浏览查询和产品追溯功能，产品追溯功能应当与当地医疗器械经营环节产品追溯系统实现数据对接
            </span>
          </div>

          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              04
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              具有销售票据或退换货票据生成、打印及销售记录保存功能
            </span>
          </div>

          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              05
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              具有质量公告和相关信息发布管理功能
            </span>
          </div>
          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              06
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              同时开展医疗器械网络批发和零售业务的第三方平台，网站或者网络客户端应用程序是否区分批发登录端口与零售登录端口
            </span>
          </div>
          <div
            style="
              background-color: #fff;
              margin: 0 40px 20px;
              border-radius: 30px;
              display: flex;
              align-items: center;
            "
          >
            <span
              style="
                width: 53px;
                height: 52px;
                background: #F55656;
                border-radius: 50%;
                border: 1px solid #000000;
                background-color: #F55656;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #FFFFFF;
                font-style: italic;
                text-align: center;
                line-height: 52px;
              "
            >
              07
            </span>
            <span
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                margin-left: 10px;
              "
            >
              入驻平台企业产品展示页面，是否标示销售企业全称、所属省份及详细地址。
            </span>
          </div>
        </div>

        <div style="margin-bottom: 90px">
          <div class="title" style="margin: 50px 0">
            医疗器械网络交易服务第三方平台备案申报资料
          </div>
          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 25%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  01
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  营业执照原件、复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  02
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  法定代表人或者主要负责人、医疗器械质量安全管理人身份证明原件、复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 20%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  03
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  组织机构与部门设置说明
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 7%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  04
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  办公场所地理位置图、（平面图）及房屋产权证明文件或者租赁协议（附房屋产权证明文件）原件、复印件
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  05
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  电信业务经营许可证原件、复印件或者非经营性互联网信息服务备案说明
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  06
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  《互联网药品信息服务资格证书》原件、复印件
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  07
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  医疗器械网络交易服务质量管理制度等文件目录
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  08
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  网站或者网络客户端应用程序基本情况介绍和功能说明
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div class="title">医疗器械网络交易服务第三方平台备案申报资料</div>
          <div
            style="
              padding: 10px;
              border: 1px dashed #ccc;
              width: 660px;
              height: 670px;
              margin: 20px auto 80px;
            "
          >
            <img src="@/assets/img/zzbl/nineteen/2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "18",
      middleList: [
        {
          title: "关于医疗器械网络交易服务第三方平台备案",
          content: [
            {
              one: "什么是医疗器械网络交易服务第三方平台备案",
              two:
                "根据《国家食品药品监督管理总局令第38号》《医疗器械网络销售监督管理办法》已于2017年11月7日经国家食品药品监督管理总局局务会议审议通过，予公布，自2018年3月1日起施行。 原《互联网药品交易许可证》分为三种业务模式，即B2B第三方平台模式(A证)、B2B模式(B证)、B2C模式(C证)。2017年先后取消了其行政审批事项。现年改为备案制，并分为《医疗器网络经营备案》和《药品网络经营备案》",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "医疗器械网络交易服务分类",
          content: [
            {
              one: "1、从事医疗器械网络销售的企业",
              two:
                "2、医疗器械网络交易服务第三方平台提供者从事医疗器械网络销售的企业、医疗器械网络交易服务第三方平台提供者应当遵守医疗器械法规、规章和规范，建立健全管理制度，依法诚信经营，保证医疗器械质量安全。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理医疗器械网络交易服务第三方平台备秦条件",
          content: [
            {
              one:
                "医疗器械网络交易服务第三方平台提供者，是在医疗器械网络交易中仅提供网页空间、虚拟交易场所、交易规则、交易撮合、电子订单等交易服务，供交易双方或者多方开展交易活动，不直接参与医疗器械销售的企业",
              two: "已经依法取得《互联网药品信息服务资格证书》",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "互联网资质代办平台",
      middleContent: "医疗器械网络交易服务第三方平台备案",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
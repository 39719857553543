<template>
  <div>
    <div style="width: 2000px; margin: 0 auto" v-if="active == 1">
      <div>
        <div class="bg" style="position: relative">
          <div style="position: absolute; left: 48%">
            <div
              style="
                font-family: Alimama ShuHeiTi;
                font-weight: bold;
                font-size: 48px;
                color: #FFFFFF;
                margin: 50px 0 30px;
              "
            >
              计算机软件著作权申请
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 18px;
                color: #FFFFFF;
                margin: 30px 0;
              "
            >
              为上千家企业、高校用户提供著作权登记和软件开发服务
            </div>
            <div class="top_content">
              <span>申请成功率为100%</span>
              <span style="margin-left: auto">加急最快一个工作日%</span>
            </div>
            <div class="top_content">
              <span>顾问提前审核免补正</span>
              <span style="margin-left: auto">行业底价，35-40工作日领证</span>
            </div>
            <div class="top_content">
              <span>可按需定制软件申请著作权</span>
              <span style="margin-left: auto"
                >适用职称评定、高企认定、项目申报</span
              >
            </div>

            <div
              style="
                width: 300px;
                height: 58px;
                line-height: 58px;
                color: #E7242A;
                background-color: #fff;
                font-family: Alimama ShuHeiTi;
                font-weight: bold;
                font-size: 24px;
                color: #EE3D33;
                text-align: center;
                margin-top: 30px;
                border-radius: 30px;
                cursor: pointer;
              "
              @click="dialogVisible = true"
            >
              立即咨询
            </div>
          </div>
        </div>
      </div>

      <div style="width: 1600px; margin: 0 auto; margin-top: 50px">
        <div style="margin-top: 70px; height: 500px; padding-bottom: 30px">
          <div style="background-color: #fff">
            <div
              style="
                padding: 30px 0 20px 0;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #333333;
                text-align: center;
                border-bottom: 1px solid #D0D0D0;
                position: relative;
              "
            >
              <div
                style="
                  height: 6px;
                  width: 75px;
                  background-color: #F55656;
                  position: absolute;
                  bottom: 0;
                  left: 48%;
                "
              ></div>
              软件著作权的重要性
            </div>

            <div
              style="
                margin: 20px 0 30px 0;
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 16px;
                color: #333333;
              "
            >
              没有被保护的作品随时面临被抄袭、侵权的风险
            </div>

            <div style="width: 1600px; margin: 0 auto; background-color: #fff">
              <div class="kh_content">
                <div class="first">
                  <div class="top_title">NO.1 帮助宣传</div>
                  <p style="margin: 50px" class="top_contents">
                    已登记的版权可以向社会 宣传自己的产品
                  </p>
                  <div class="top_btn" @click="dialogVisible = true">
                    免费咨询
                  </div>
                </div>
                <div class="first">
                  <div class="top_title" style="background-color: #e59311">
                    NO.2 防止抄袭
                  </div>
                  <p style="margin: 50px" class="top_contents">
                    当自己的版权被侵权时,可 应采取法律措施,维护自己 的权益
                  </p>
                  <div class="top_btn" @click="dialogVisible = true">
                    免费咨询
                  </div>
                </div>
                <div class="first">
                  <div class="top_title">NO.3 提升无形资产</div>
                  <p style="margin: 50px" class="top_contents">
                    帮助企业增资，提升递延 纳税优惠
                  </p>
                  <div class="top_btn" @click="dialogVisible = true">
                    免费咨询
                  </div>
                </div>
                <div class="first">
                  <div class="top_title" style="background-color: #e59311">
                    NO.4 平台入驻
                  </div>
                  <p style="margin: 50px" class="top_contents">
                    腾讯/阿里/百度等大平台 入驻必备条件
                  </p>
                  <div class="top_btn" @click="dialogVisible = true">
                    免费咨询
                  </div>
                </div>
                <div class="first" style="border: 0">
                  <div class="top_title">NO.5 减免税收</div>
                  <p style="margin: 50px" class="top_contents">
                    办理高新技术企业认定的 前提条件, 并且享受百万 政府补贴
                  </p>
                  <div class="top_btn" @click="dialogVisible = true">
                    免费咨询
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="height: 400px; padding-bottom: 30px">
          <div
            style="
              padding: 30px 0 50px 0;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              text-align: center;
            "
          >
            你的同行，80%保护了以下作品
          </div>
          <div style="display: flex">
            <div style="position: relative">
              <img
                style="height: 300px"
                src="@/assets/img/propertyRight/2.png"
                alt=""
              />
              <div
                style="
                  position: absolute;
                  bottom: 70px;
                  left: 20px;
                  width: 36px;
                  height: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  line-height: 77px;
                  z-index: 99;
                "
              >
                APP
              </div>
            </div>
            <div>
              <div style="position: relative">
                <img
                  style="height: 150px; width: 440px"
                  src="@/assets/img/propertyRight/3.png"
                  alt=""
                />
                <div
                  style="
                    position: absolute;
                    bottom: 70px;
                    left: 20px;
                    height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 77px;
                    z-index: 99;
                  "
                >
                  pc网站
                </div>
              </div>
              <div style="display: flex; position: relative">
                <img
                  style="height: 150px; width: 220px"
                  src="@/assets/img/propertyRight/4.png"
                  alt=""
                />
                <div
                  style="
                    position: absolute;
                    bottom: 70px;
                    left: 250px;
                    height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 77px;
                    z-index: 99;
                  "
                >
                  wap网站
                </div>
                <img
                  style="height: 150px; width: 220px"
                  src="@/assets/img/propertyRight/5.png"
                  alt=""
                />
                <div
                  style="
                    position: absolute;
                    bottom: 70px;
                    left: 20px;
                    height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 77px;
                    z-index: 99;
                  "
                >
                  pc网站
                </div>
              </div>
            </div>
            <div style="position: relative;">
              <img
                style="height: 300px"
                src="@/assets/img/propertyRight/6.png"
                alt=""
              />
              <div
                style="
                  position: absolute;
                  bottom: 70px;
                  left: 20px;
                  width: 36px;
                  height: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  line-height: 77px;
                  z-index: 99;
                "
              >
              游戏
              </div>
            </div>
            <div>
              <div style="position: relative;">
                <img
                  style="height: 150px; width: 440px"
                  src="@/assets/img/propertyRight/7.png"
                  alt=""
                />
                <div
                  style="
                    position: absolute;
                    bottom: 70px;
                    left: 20px;
                    height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 77px;
                    z-index: 99;
                  "
                >
                微信小程序
                </div>
              </div>
              <div style="position: relative;">
                <img
                  style="height: 150px; width: 440px"
                  src="@/assets/img/propertyRight/8.png"
                  alt=""
                />
                <div
                  style="
                    position: absolute;
                    bottom: 70px;
                    left: 20px;
                    height: 14px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    line-height: 77px;
                    z-index: 99;
                  "
                >
                5H
                </div>
              </div>
            </div>
            <div style="position: relative;">
              <img
                style="height: 300px"
                src="@/assets/img/propertyRight/9.png"
                alt=""
              />
              <div
                style="
                  position: absolute;
                  bottom: 70px;
                  left: 20px;
                  width: 36px;
                  height: 14px;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  line-height: 77px;
                  z-index: 99;
                "
              >
              插件
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 2000px; margin: 0 auto; margin-top: 50px">
        <div style="height: 550px" class="g_bg">
          <div
            style="
              width: 1600px;
              margin: 0 auto;
              padding-top: 50px;
              position: relative;
            "
          >
            <div
              style="
                margin: 10px 0 30px 0;
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #fff;
              "
            >
              软件著作权申请流程
            </div>

            <div style="margin-top: 80px; margin-left: 45px">
              <div style="display: flex; align-items: center">
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/15.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    公告发证
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/16.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    提供程序
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/17.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    提供手册
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/18.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    确认付款
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/19.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    盖章快递
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/20.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    报送审查
                  </div>
                </div>

                <div style="margin: 0 25px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>

                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 120px; height: 120px"
                      src="@/assets/img/propertyRight/21.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    开户
                  </div>
                </div>
              </div>

              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-top: 70px;
                "
              >
                第一步：您填写软件基本信息采集表
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-top: 20px;
                  display: flex;
                  align-items: center;
                "
              >
                <div>我们会根据信息采集表内容为您整理需要盖章签字的材料 ；</div>
                <span
                  style="
                    display: inline-block;
                    width: 139px;
                    height: 44px;
                    line-height: 44px;
                    text-align: center;
                    border: 1px solid #FFFFFF;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin-left: 20px;
                  "
                  >点此下载
                  <i class="el-icon-download" style="font-size: 20px"></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 0 auto; height: 500px">
          <div
            style="
              margin: 70px 0 15px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              text-align: center;
            "
          >
            证书展示
          </div>
          <div
            style="
              margin-bottom: 50px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            CERTIFICATE DISPLAY
          </div>

          <div style="display: flex; align-items: center; height: 350px">
            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                padding: 15px;
                border-radius: 5px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/propertyRight/11.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 16px;
                  color: #333333;
                  margin-top: 20px;
                  text-align: center;
                "
              >
                大医生APP
              </div>
            </div>

            <div
              style="flex: 1; height: 100%; margin-right: 25px; padding: 15px"
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/propertyRight/12.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 16px;
                  color: #333333;
                  margin-top: 20px;
                  text-align: center;
                "
              >
                彩票APP
              </div>
            </div>
            <div
              style="flex: 1; height: 100%; margin-right: 25px; padding: 15px"
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/propertyRight/13.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 16px;
                  color: #333333;
                  margin-top: 20px;
                  text-align: center;
                "
              >
                游戏软件
              </div>
            </div>
            <div
              style="flex: 1; height: 100%; margin-right: 25px; padding: 15px"
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/propertyRight/14.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 16px;
                  color: #333333;
                  margin-top: 20px;
                  text-align: center;
                "
              >
                教育系统
              </div>
            </div>
            <div
              style="flex: 1; height: 100%; margin-right: 25px; padding: 15px"
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/propertyRight/14.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 16px;
                  color: #333333;
                  margin-top: 20px;
                  text-align: center;
                "
              >
                找朋友软件
              </div>
            </div>
          </div>
        </div>
      </div>

      <bottomWiedt
        :bottomTitle="bottomTitle"
        style="margin-top: 30px"
      ></bottomWiedt>
    </div>

    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
import Dialog from "@/components/dialog";

export default {
  components: {
    bottomWiedt,
    Dialog,
  },
  data() {
    return {
      active: "",
      bottomTitle: "创业前沿资讯",
      dialogVisible: false,
    };
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.active = now.params.id;
        console.log(now, "=====");
      },
      immediate: true, //立即执行
    },
  },
  created() {
    this.active = this.$route.params.id;
  },
  methods: {
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.top_btn {
  border-radius: 21px;
  border: 1px solid #181818;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #707070;
  width: 215px;
  height: 36px;
  line-height: 36px;
  margin-left: 50px;
  cursor: pointer;
}
.top_contents {
  height: 70px;
  margin: 50px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.top_title {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #ee3d33;
  border: 1px solid #DEDEDE;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #fff;
}
.top_content {
  text-align: left;
  margin-bottom: 10px;
  display: flex;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
.top_content span {
  flex: 1;
}
.bg {
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/1 (2).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}
.num {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 29px;
  font-style: italic;
}
.g_last {
  display: flex;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  bottom: -240px;
  background: linear-gradient(
    to right,
    #f68c5d,
    #fcb271,
    #fdd98e,
    #fbee9d,
    #fa9c63FF,
    #f6d792
  );
}
.g_bg {
  border-radius: 5px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/fgd.jpg");
  background-size: cover;
  width: 2000px;
  margin: 0 auto;
  height: 100%; /* 或具体像素值，如 500px */
  background-position: center center;
  background-repeat: no-repeat;
}
.kh_content {
  height: 300px;
  display: flex;
  margin-bottom: 15px;
  border: 1px solid #DEDEDE;
}
.kh_content .first {
  flex: 1;
  text-align: center;
  border-right: 1px solid #DEDEDE;
  margin-right: 7px;
}
.kh_content div img {
  margin: 30px auto;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "22",
      middleList: [
        {
          title: "哪些单位需要办理互联网公安备案",
          content: [
            {
              one: "根据《计算机信息网络国际联网安全保护管理办法》中规定:",
              two:
                "1、用户在接入单位办理入网手续时，应当填写用户备案表。备案表由公安部监制。",
              three:
                "2、互联单位、接入单位、使用计算机信息网络国际联网的法人和其他组织(包括跨省、自治区、直辖市联网的单位和所属的分支机构)，应当自网络正式连通之日起三十日内，到所在地的省、自治区、直辖市人民政府公安机关指定的受理机关办理备案手续。",
              four:
                "前款所列单位应当负责将接入本网络的接入单位和用户情况报当地公安机关备案，并及时报告本网络中接入单位和用户的变更情况。",
              five:
                "3、涉及国家事务、经济建设、国防建设、尖端科学技术等重要领域的单位办理备案手续时，应出具其行政主管部门的审批证明。",
              six:
                "根据相关法律法规规定，凡是接入互联网的单位:包括互联网接入服务单位(1SP)、互联网数据中心(DC)、互联网信息服务单位(ICP)和国际联网使用单位，都需要到公安机关办理备案手续。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "公安网备办理流程",
          content: [
            {
              one: "一、受理",
              two:
                "互联单位、接入单位、使用计算机信息网络国际联网的法人和其他组织法定代表人或被委托人携带身份证件及委托手续及相关材料(单位应提交工商营业执照副本，个人应当提交身份证复印件)，到所在地公安机关(单位开办为主要办公地、个人开办为居住地)受理，发放《北京市计算机信息网络国际联网单位备案表》",
              three:
                "网络安全保卫总队受理大型互联单位、接入单位、使用计算机信息网络国际联网的法人和其他组织的联网备案，公共交通安全保卫总队、内部单位保卫局、文化保卫总队受理保卫关系单位的联网备案，分(县)局网安部门受理其他单位和组织的联网备案。",
              four: "二、审核",
              five: "一是备案表格料填写是否完整，是否符合要求;",
              six: "二是备案材料是否齐全;",
              seven:
                "三是应要求申请人签署《计算机信息网络国际联网单位信息网络安全保卫责任书》。;",
              eight: "三、告知与送达",
              nine:
                "制定统一编号，并在《备案表》标注，将一份《备案表》交申请人，并告知申请人依法需将备案编号标记在本网站首页最下方醒目位置。或登录 http://www.beian.gov.cn进行登记办理",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },

        {
          title: "不履行公安机关联网备案的法律后果",
          content: [
            {
              one:
                "根据《计算机信息网络国际联网安全保护管理办法》(公安部令第33号)的 第二十三条规定“违反本办法第十一条、第十二条规定，不履行备案职责的，由公安机关给予警告或者停机整顿不超过六个月的处罚。”",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "公安网备",
      middleContent:
        "为加强对计算机信息网络国际联网的安全保护，维护公共秩序和社会稳定，根据《计算机信息网络国际联网安全保护管理办法》【公安部33号令】等有关法规，凡是在当地市行政区域范围内的互联网接入单位、信息服务单位及与国际互联网联网的单位（或个人）均应当在网络正式联通后的三十日内，到当地公安机关网安部门登记备案。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin: 50px 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            出版物经营许可证服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          出版物经营许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "14",
      middleList: [
        {
          title: "出版物经营许可证办理机构",
          content: [
            {
              one: "出版物经营许可证(批发)申办机构:各地新闻出版广电局",
              two: "出版物经营许可证(零售)申办机构:各市区文化委",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请出版物经营许可证开展业务有何处罚?",
          content: [
            {
              one:
                "未经批准，擅自设立出版物的出版、印别或者复制、进口、发行单位，或者擅自从事出版物的出版、印刷或者复制、进口、发行业务，假冒出版单位名称或者伪造。假冒报纸、期刊名称出版出版物的，由出版行政部门、工商行政管理部门依照法定职权予以取缔;依照刑法关于非法经营罪的规定，依法追究刑事责任;尚不够刑事处罚的，没收出版物、违法所得和从事违法活动的专用工具、设备，违法经营额1万元以上的，并处违法经营额5倍以上10倍以下的罚款，违法经营额不足1万元的。并处1万元以上5万元以下的罚款;侵犯他人合法权益的，依法承担民事责任。",
            },
          ],
          remerk: "",
          icon: false,
          img: "14",
          iconImg: "",
        },
        {
          title: "出版物经营许可证办理条件",
          content: [
            {
              one: "(一)有确定的企业名称和经营范围;",
              two: "(二)有与出版物业务相适应的组织机构和发行人员;",
              three: "(三)有与出版物批发业务相适应的设备和固定的经营场所。",
              four: "(四)有相适应的注册资金。",
              five:
                "(五)最近三年内未受到新闻出版行政部门行政处罚无其他严重违法记录。",
              six: "(六)法律、行政法规和国家新闻出版局规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "出版物经营许可证批发所需材料",
          content: [
            {
              one: "(一)新闻出版局领取出版物经营许可证审批表;",
              two:
                "(二)申请书，载明单位的名称、地址、法定代表人或者主要负责人的姓名、住址、资本来源、资本数额;",
              three: "(三)企业章程。",
              four: "(四)注册资本500万元的资信证明。",
              five:
                "(五)新闻出版局领取出版物经营许可证经营场所的情况及使用权证明。",
              six: "(六)法定代表人身份证明复印件一份。",
              seven:
                "(七)法定代表人或者主要负责人的职业资格证书中级及复印件一份。",
              eight: "(八)相应计算机管理条件的证明材料。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "出版物经营许可证零售所需材料",
          content: [
            {
              one: "(一)核名通知书或执照副本原件(增项企业用);",
              two: "(二)验资报告;",
              three: "(三)房产证复印件、租赁协议。",
              four: "(四)公司座机，手机，邮编。",
              five: "(五)公司章程。",
              six: "(六)法人的初级职称图书发行员证书。",
              seven: "(七)所有股东的身份证复印件。",
              eight: "(八)增项企业需要提供公章。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "个人可以办理出版物经营许可证吗?",
          content: [
            {
              one:
                "现在淘宝等网上卖书都需要商家办理《出版物经营许可证》，更多人关心个人是否可以办理出版物经营许可证。「启盈盈」告诉大家，个人(自然人)是不可以办理《出版物经营许可证》的。先办理《出版物经营许可证》前提我们要注册公司或者个体工商户，如果您想了解更详细些，请联系我们。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "出版物许可证申请办理",
      middleContent:
        "出版物经营许可证又可以称为图书经营许可证，是指从事报纸、期刊、图书、电子出版物等批发或者零售业务的企业需要办理的行政性许可证书。出版物经营许可证所涵盖的业务内容可以分为批发和零售两种，其办理对象是:申请设立出版物零售、出租企业或者其他单位和个人申请从事出版物零售、出租业务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto 50px;
          padding-bottom: 30px;
        "
      >
        <div>
          <div class="title">呼叫中心许可证申请代理服务内容</div>
          <div class="title_middle" style="margin: 15px 0">
            全程无须您亲自到场/双向快递、极速拿照
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
              margin-bottom: 20px;
            "
          >
            费用包含：呼叫中心许可证证正本及附页；专业技术方案的提供、专业技术人员派遣、难点技术攻关、业务指导
          </div>
          <div style="text-align: center">
            <img src="@/assets/img/zzbl/four/2.png" alt="" />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            <div style="margin: 20px 50px">呼叫中心许可证样本</div>
            <div>
              证书内容:名称、编号、导语、申办单位名称、法定代表人、注册资本、
            </div>
            <div style="margin: 10px 0; padding-bottom: 70px">
              注册住所、业务种类、业务覆盖范围、签发人、发证机关盖章、发证日期、有效期。
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 90px">
        <div class="title" style="margin: 50px 0">
          办理呼叫中心许可证需要的材料
        </div>
        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 10%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                01
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请呼叫中心许可证公司的企业营业执照副本。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 6%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                02
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请呼叫中心许可证公司章程(加盖工商局档案查询章原件)
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                03
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                完整详细的股权结构图(签定协议后，由「爱听企服」提供)
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                04
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司主要管理、技术人员的清单列表(含姓名、职务、学历、联系方式等)，以及身份证复印件(不少于3人)。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                05
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                公司为员工所上近三个月的社保证明(不少于3人)(应加盖社保机构红章)
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                06
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                至少3名员工的劳动合同原件(原件会退回)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "4",
      middleList: [
        {
          title: "什么是呼叫中心许可证?",
          content: [
            {
              one:
                "呼叫中心许可证是第二类增值电信业务经营许可证的简称。分为全网呼叫中心证和地网呼叫中心证。是工业信息化产业部门同意企业运营电话呼入和呼出业务服务，而为企业颁发的许可证。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "呼叫中心许可证审批机构?",
          content: [
            {
              one:
                "全网呼叫中心许可证在工信部办理，地网呼叫中心许可证在各省通信管理局办理。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "呼叫中心许可证年检要求",
          content: [
            {
              one: "呼叫中心证年检是每年的1月--3月",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "呼叫中心许可证有效期限",
          content: [
            {
              one: "呼叫中心经营许可证使用年限为5年有效期、5年后需要办理续期。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "呼叫中心许可证办理条件",
          content: [
            {
              one: "(一)经营者为依法设立的公司。",
              two: "(二)有与开展经营活动相适应的资金和专业人员。",
              three: "(三)有为用户提供长期服务的信誉或者能力。",
              four: "(四)省网业务的注册资本最低限额为100万元人民币。",
              five:
                "(五)全网业务的注册资金最低限额为1000万元人民币，且至少在2个省设立分公司。",
              six: "(六)有必要的场地、设施及技术方案。",
              seven:
                "(七)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录。",
              eight: "(八)国家规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "呼叫中心许可证申请",
      middleContent:
        "呼叫中心业务是指受企事业等相关单位委托，利用与公用通信网或互联网连接的呼叫中心系统和数据库技术，经过信息采集、加工、存储等建立信息库，通过公用通信网向用户提供有关该单位的业务咨询、信息咨询和数据查询等服务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div style="width: 1400px; margin: 0 auto">
    <div style="display: flex; margin-top: 30px">
      <div>
        <!-- <img src="middleImg" alt="" /> -->
        <img v-if="middleImg == 1" src="@/assets/img/zzbl/one/1.png" alt="" />
        <img v-if="middleImg == 2" src="@/assets/img/zzbl/two/1.png" alt="" />
        <img v-if="middleImg == 3" src="@/assets/img/zzbl/three/1.png" alt="" />
        <img v-if="middleImg == 4" src="@/assets/img/zzbl/four/1.png" alt="" />
        <img v-if="middleImg == 5" src="@/assets/img/zzbl/five/1.png" alt="" />
        <img v-if="middleImg == 6" src="@/assets/img/zzbl/six/1.png" alt="" />
        <img v-if="middleImg == 7" src="@/assets/img/zzbl/seven/1.png" alt="" />
        <img v-if="middleImg == 8" src="@/assets/img/zzbl/nine.png" alt="" />
        <img v-if="middleImg == 9" src="@/assets/img/zzbl/ten/1.png" alt="" />
        <img v-if="middleImg == 10" src="@/assets/img/zzbl/eleven.png" alt="" />
        <img v-if="middleImg == 11" src="@/assets/img/zzbl/twelve.png" alt="" />
        <img
          v-if="middleImg == 12"
          src="@/assets/img/zzbl/thirteen.png"
          alt=""
        />
        <img
          v-if="middleImg == 13"
          src="@/assets/img/zzbl/fourteen/1.png"
          alt=""
        />
        <img
          v-if="middleImg == 14"
          src="@/assets/img/zzbl/fifteen/1.png"
          alt=""
        />
        <img
          v-if="middleImg == 15"
          src="@/assets/img/zzbl/sixteen.png"
          alt=""
        />

        <img
          v-if="middleImg == 16"
          src="@/assets/img/zzbl/seventeen/1.png"
          alt=""
        />

        <img
          v-if="middleImg == '17'"
          src="@/assets/img/zzbl/eighteen/1.png"
          alt=""
        />

        <img
          v-if="middleImg == '18'"
          src="@/assets/img/zzbl/nineteen/1.png"
          alt=""
        />

        <img
          v-if="middleImg == '19'"
          src="@/assets/img/zzbl/twenty/1.png"
          alt=""
        />

        <img
          v-if="middleImg == '20'"
          src="@/assets/img/zzbl/twenty-one/1.png"
          alt=""
        />

        <img v-if="middleImg == '21'" src="@/assets/img/zzbl/22/1.png" alt="" />

        <img v-if="middleImg == '22'" src="@/assets/img/zzbl/23.png" alt="" />

        <img v-if="middleImg == '23'" src="@/assets/img/zzbl/24.png" alt="" />

        <img v-if="middleImg == '24'" src="@/assets/img/zzbl/25/1.png" alt="" />

        <img v-if="middleImg == '26'" src="@/assets/img/zzbl/26.png" alt="" />

        <img v-if="middleImg == '27'" src="@/assets/img/zzbl/27/1.png" alt="" />

        <img v-if="middleImg == '28'" src="@/assets/img/zzbl/28/1.png" alt="" />

        <img v-if="middleImg == '29'" src="@/assets/img/zzbl/29/1.png" alt="" />

        <img v-if="middleImg == '30'" src="@/assets/img/zzbl/30/1.png" alt="" />

        <img v-if="middleImg == '31'" src="@/assets/img/zzbl/31.png" alt="" />

        <img v-if="middleImg == '32'" src="@/assets/img/zzbl/32.png" alt="" />

        <img v-if="middleImg == '33'" src="@/assets/img/zzbl/33.png" alt="" />

        <img v-if="middleImg == '34'" src="@/assets/img/zzbl/34.png" alt="" />
      </div>
      <div style="margin-left: 30px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 30px;
            color: #333333;
            position: relative;
          "
        >
          <div
            style="
              height: 5px;
              width: 75px;
              background-color: #F55656;
              position: absolute;
              bottom: -15px;
            "
          ></div>
          {{ middleTitle }}
        </div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            margin-top: 50px;
          "
        >
          {{ middleContent }}
        </div>

        <div style="display: flex; align-items: center; margin-top: 100px">
          <div
            style="
              width: 148px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #F55656;
              border-radius: 8px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #FFFFFF;
              margin-right: 20px;
              cursor: pointer;
            "
            @click="dialogVisible = true"
          >
            立即办理
          </div>

          <div
            style="
              width: 148px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              background: #FFFFFF;
              border-radius: 8px;
              border: 1px solid #F55656;
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #F55656;
              cursor: pointer;
            "
            @click="dialogVisible = true"
          >
            在线询价
          </div>
        </div>
      </div>
    </div>

    <div style="background-color: #f4f4f4; padding: 30px">
      <div
        v-for="(item, index) in middleList"
        :key="index"
        style="margin-bottom: 50px"
      >
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div
            style="
              height: 5px;
              width: 75px;
              background-color: #F55656;
              position: absolute;
              bottom: -3px;
            "
          ></div>
          {{ item.title }}
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #F55656;
            margin-top: 30px;
          "
          v-if="item.remerk && item.remerk != ''"
        >
          {{ item.remerk }}
        </div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            margin-top: 50px;
          "
          v-for="(items, inx) in item.content"
          :key="inx"
        >
          <div
            v-if="item.iconImg == '2'"
            style="display: flex; align-items: center; margin-bottom: 30px"
          >
            <div style="text-align: center">
              <img src="@/assets/img/zzbl/two/2.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                "
              >
                全网SP证
              </div>
            </div>
            <div style="margin-left: 100px">
              <div>
                <img src="@/assets/img/zzbl/two/3.png" alt="" />
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    color: #333333;
                  "
                >
                  地网SP证
                </div>
              </div>
            </div>
          </div>
          <p v-if="items.one">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i>
            {{ items.one }}
          </p>
          <p v-if="items.two">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.two }}
          </p>
          <p v-if="items.three">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.three }}
          </p>
          <p v-if="items.four">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.four }}
          </p>
          <p v-if="items.five">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.five }}
          </p>
          <p v-if="items.six">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.six }}
          </p>
          <p v-if="items.seven">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.seven }}
          </p>
          <p v-if="items.eight">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.eight }}
          </p>
          <p v-if="items.nine">
            <i
              v-if="item.icon == true"
              class="el-icon-circle-check iconStyle"
              style="color: #f55656; font-size: 20px"
            ></i
            >{{ items.nine }}
          </p>
          <img
            v-if="item.img == '13'"
            src="@/assets/img/zzbl/fourteen/2.png"
            alt=""
          />
          <img
            v-if="item.img == '14'"
            src="@/assets/img/zzbl/fifteen/2.png"
            alt=""
          />

          <img
            v-if="item.img == '16'"
            src="@/assets/img/zzbl/seventeen/2.png"
            alt=""
          />

          <img
            v-if="item.img == '19'"
            src="@/assets/img/zzbl/twenty/2.png"
            alt=""
          />

          <img
            v-if="item.img == '20'"
            src="@/assets/img/zzbl/twenty-one/2.png"
            alt=""
          />
          <img
            v-if="item.img == '25'"
            src="@/assets/img/zzbl/25/2.png"
            alt=""
          />
          <img
            v-if="item.img == '26'"
            src="@/assets/img/zzbl/25/3.png"
            alt=""
          />

          <img
            v-if="item.img == '27'"
            src="@/assets/img/zzbl/25/4.png"
            alt=""
          />

          <div style="width: 806px; margin: 0 auto">
            <img
              v-if="item.img == '28'"
              src="@/assets/img/zzbl/25/5.png"
              alt=""
            />
          </div>

          <img
            v-if="item.img == '29'"
            src="@/assets/img/zzbl/29/2.png"
            alt=""
          />

          <img
            v-if="item.img == '30'"
            src="@/assets/img/zzbl/30/2.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/dialog";
export default {
  components: {
    Dialog,
  },
  props: {
    middleImg: {
      type: String,
      default: "",
    },
    middleTitle: {
      type: String,
      default: "",
    },
    middleContent: {
      type: String,
      default: "",
    },
    middleList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.iconStyle {
  margin-right: 10px;
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto 50px;
          padding-bottom: 30px;
        "
      >
        <div style="margin: 0 30px">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              padding: 30px 0;
              border-bottom: 1px solid #D0D0D0;
              position: relative;
              margin-bottom: 30px;
            "
          >
            <div class="titles"></div>
            网络文化经营许可证经营性分类
          </div>

          <div
            style="
              background-color: #e7e7e7;
              display: flex;
              justify-content: center;
              padding: 0px 80px;
            "
          >
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                音像
              </div>
            </div>
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                游戏
              </div>
            </div>
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                演出
              </div>
            </div>
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/6.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                艺术
              </div>
            </div>
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/7.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                动画
              </div>
            </div>
            <div class="ICP_content">
              <div style="margin: 0 auto">
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/29/8.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                其他
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            网络文化经营许可证服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
        <div class="title" style="margin-bottom: 35px">
          网络文化经营许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "29",
      middleList: [
        {
          title: "哪些企业需办网络文化经营许可证",
          content: [
            {
              one: "1、网络经营音乐娱乐产品",
              two: "2、游戏产品网络经营(已暂停)",
              three: "3、网络经营艺术品网络经营",
              four: "4、演出剧(节)目、表演网络经营",
              five: "5、网络经营动漫产品",
              six: "6、网络展览、比赛活动",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理条件要求",
          content: [
            {
              one: "1、有单位的名称、住所、组织机构和章程",
              two: "2、有确定的互联网文化活动范围;",
              three:
                "3、有适应互联网文化活动需要并取得相应从业资格的规定数量的业务管理人员和专业技术人员:",
              four:
                "4、有适应互联网文化活动需要的设备、工作场所以及相应的经营管理技术措施。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "申请时需要提交的材料清单",
          content: [
            {
              one: "1、企业发展可行性报告;",
              two: "2、设立经营性网络文化单位申请表;",
              three: "3、相应业务管理人员、专业技术人员的身份及学历证明;",
              four: "4、域名注册证书和服务器托管相关资质证明材料;",
              five: "5、其他政府部门规定需要的有关申请材料。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "如何区分和辨别“网络文化经营许可证”",
          content: [
            {
              one:
                "《网络文化经营许可证》，是指经文化行政部门和电信管理机构批准，颁发给从事经营性互联网文化活动的互联网信息服务提供者的市场合法准入资质;其中经营性互联网文化活动是指以营利为目的，通过向上网用户收费或者电子商务、广告、赞助等方式获取利益，提供互联网文化产品及其服务的活动。",
            },
          ],
          remerk: "",
          icon: false,
          img: "29",
          iconImg: "",
        },
      ],
      middleTitle: "网络文化经营许可证",
      middleContent:
        "设立经营性互联网文化单位，开展通过提供互联网文化产品及其服务的活动办理时间：20—40工作日 各省时间有差异，详情咨询客服法律依据：《互联网文化管理暂行办法》、《网络游戏管理暂行办法》等",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
}
.titles {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
<template>
  <div>
    <div style="position: fixed;top: 0;z-index: 9999999999999;">
      <div class="navTop" v-if="moreActive == false">
        <img class="imgStyle" src="@/assets/img/nav/navlogos.png" alt="" />
        <img class="dianhua" src="@/assets/img/nav/dianhua.png" alt="" />
        <div class="dianhuaNum">400-0066-991</div>
        <div class="kongzhitai" @click="goHerf">控制台</div>
        <img
          @click="more"
          class="more"
          src="@/assets/m_img/nav/gengduo.png"
          alt=""
        />
      </div>
    </div>
    <div
      v-if="moreActive == true"
      style="
        padding-top: 1px;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 99999;
      "
    >
      <div
        style="
          border-bottom: 1px solid #d3d3d3;
          border-top: 1px solid #d3d3d3;
          display: flex;
          padding: 5px 0;
          background-color: #fff;
        "
      >
        <i
          @click="moreActive = false"
          style="margin-left: auto; margin-right: 10px"
          class="el-icon-close"
        ></i>
      </div>
      <el-tabs
        tab-position="left"
        style="min-height: 100vh; background-color: #fff"
      >
        <el-tab-pane label="首页"> </el-tab-pane>
        <el-tab-pane label="工商财税">工商财税</el-tab-pane>
        <el-tab-pane label="资质办理">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="增值电信业务经营许可证" name="1">
              <div>互联网信息服务业务(ICP证)</div>
              <div>移动网信息服务业务(SP证)</div>
              <div>在线数据处理业务(EDI)</div>
              <div>呼叫中心业务</div>
              <div>互联网接入服务业务(ISP证)</div>
              <div>数据中心业务(IDC证)</div>
              <div>内容分发网络业务(CDN证)</div>
              <div>国内多方通信服务业务</div>
              <div>网络托管业务</div>
              <div>存储转发业务</div>
              <div>外商投资经营电信业务审批</div>
              <div>95号码</div>
              <div>96号码</div>
              <div>1069码号</div>
              <div>10698码号</div>
            </el-collapse-item>
            <el-collapse-item title="网络文化/网络游戏" name="2">
              <div>网络文化经营许可证</div>
              <div>营业性演出许可证</div>
              <div>艺术品经营单位备案</div>
              <div>美术品进出口经营</div>
              <div>游戏版号</div>
              <div>游戏备案</div>
              <div>活动审批</div>
            </el-collapse-item>
            <el-collapse-item title="影视审批" name="3">
              <div>广播电视节目许可证</div>
              <div>营业性演出许可证</div>
            </el-collapse-item>
            <el-collapse-item title="影视审批" name="4">
              <div>网络出版许可证</div>
              <div>出版物经营许可证</div>
              <div>互联网新闻信息服务许可证</div>
            </el-collapse-item>
            <el-collapse-item title="影视审批" name="5">
              <div>互联网药品信息服务</div>
              <div>互联网药品交易服务</div>
              <div>医疗器械网络交易第三方备案</div>
            </el-collapse-item>
            <el-collapse-item title="网站备案" name="6">
              <div>ICP网站域名备案</div>
              <div>公安网备</div>
              <div>经营性网站备案</div>
              <div>信息系统安全等级保护备案</div>
              <div>商业特许经营备案</div>
              <div>许可年检</div>
              <div>可信网站</div>
            </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="知识产权">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="商标注册" name="1"> </el-collapse-item>
            <el-collapse-item title="版权服务" name="2">
              <div>计算机软件著作权</div>
              <div>计算机软件著作权加急</div>
              <div>软件著作权软件定制开发</div>
              <div>作品著作权登记</div>
            </el-collapse-item>
            <el-collapse-item title="专利服务" name="3">
              <div>发明专利</div>
              <div>实用新型专利</div>
              <div>外观设计专利</div>
              <div>专利转让</div>
            </el-collapse-item>
            <el-collapse-item title="企业认证" name="4"> </el-collapse-item>
          </el-collapse>
        </el-tab-pane>
        <el-tab-pane label="知识课堂">知识课堂</el-tab-pane>
        <el-tab-pane label="资质转让">资质转让</el-tab-pane>
        <el-tab-pane label="关于我们">关于我们</el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    internalActiveName: {
      type: String,
    },
  },
  data() {
    return {
      // activeName: "1",
      activeName: "1",
      isActive: "0",
      isClassRoom: false,
      isNum: null,
      isUs: false,
      isUsNum: null,
      isPropertyRight: false,
      isNavActive: null,
      iszzblNavActive: null,
      iszzbl: false,

      moreActive: false,
    };
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        if (now.name == "index") {
          this.isActive = "0";
        } else if (now.name == "finance") {
          this.isActive = "1";
        } else if (now.name == "qualifications") {
          this.isActive = "2";
        } else if (now.name == "propertyRight") {
          this.isActive = "3";
        } else if (now.name == "classroom") {
          this.isActive = "4";
        } else if (now.name == "us") {
          this.isActive = "6";
        }
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    more() {
      this.moreActive = true;
    },
    goHerf() {
      window.location.href = "https://admin.atqf.cn";
    },
    zzblNavClick(val) {
      console.log(val, "vvvvvvvvvvvaal");
      this.iszzblNavActive = val;
      this.$router.push({ name: "qualifications", query: { id: val } });
      this.iszzbl = false;
    },
    navClick(val) {
      this.isNavActive = val;
      this.$router.push({ name: "propertyRight", params: { id: val } });
      this.isPropertyRight = false;
    },
    zsktTouchend() {
      this.isClassRoom = false;
    },
    zsktMousemove() {
      this.isClassRoom = true;
    },
    zzblMousemove() {
      this.iszzbl = true;
    },
    zzblTouchend() {
      this.iszzbl = false;
    },
    zscqMousemove() {
      this.isPropertyRight = true;
    },
    zscqTouchend() {
      this.isPropertyRight = false;
    },
    isUsClick(val) {
      this.isUsNum = val;
      this.$router.push("/us/" + 0);
    },
    changeClick(val) {
      this.isNum = val;
      this.$router.push("/classroom/" + 0);
    },
    handleClick(tab) {
      // let tab = val.index;
      this.isActive = tab;
      if (tab == 0) {
        this.$router.push("/");
      }
      if (tab == 1) {
        this.$router.push("/finance");
      }
      if (tab == 2) {
        this.$router.push({ name: "qualifications", query: { id: null } });
      }
      if (tab == 3) {
        this.$router.push("/propertyRight/" + 0);
      }

      if (tab == 4) {
        this.isClassRoom = true;
        // this.$router.push("/classroom");
        this.$router.push("/classroom/" + 0);
        this.isClassRoom = false;
      } else {
        this.isClassRoom = false;
      }

      if (tab == 6) {
        this.isUs = true;
        this.$router.push("/us/" + 0);
        this.isUs = false;
      } else {
        this.isUs = false;
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-tabs__item.is-active {
  background-color: #fff !important;
  color: #000 !important;
}
::v-deep .el-tabs--left .el-tabs__active-bar.is-left {
  left: 5px !important;
  background-color: #ee3d33 !important;
}
::v-deep .el-tabs--left .el-tabs__item.is-left {
  text-align: left !important;
}
::v-deep .el-tabs__nav {
  min-height: 80vh !important;
  background-color: #f6f6f6 !important;
}
::v-deep .el-collapse-item__header {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}
/* 手机端 */
@media screen and (max-width: 767px) {
  /* 样式规则 */
  .navTop {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: #fff;
    width: 93vw;
  }
  .imgStyle {
    width: 89px;
    height: 22px;
  }
  .dianhua {
    width: 12px;
    height: 12px;
    margin-left: auto;
  }
  .dianhuaNum {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 12px;
    color: #FF0000;
    margin-left: 4px;
    margin-right: 10px;
  }
  .kongzhitai {
    background-color: #ff2626;
    color: #fff;
    padding: 3px 10px;
    border-radius: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }
  .more {
    width: 14px;
    height: 14px;
    margin-left: 10px;
  }
}

/* 平板端 */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* 样式规则 */

  .imgStyle {
    width: 178px;
    height: 42px;
  }
  .navTop {
    display: flex;
    align-items: center;
    padding: 15px 25px;
  }
  .dianhua {
    width: 20px;
    height: 20px;
    margin-left: auto;
  }
  .dianhuaNum {
    font-family: Source Han Sans CN;
    font-weight: bold;
    font-size: 20px;
    color: #FF0000;
    margin-left: 4px;
    margin-right: 15px;
  }
  .kongzhitai {
    background-color: #ff2626;
    color: #fff;
    padding: 5px 15px;
    border-radius: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }
  .more {
    width: 20px;
    height: 20px;
    margin-left: 15px;
  }
}
</style>
<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 0 20px 50px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          可信网站解决网站安全问题
        </div>
        <div class="box_contents">
          可信网站认证具备"网站运行监护、网页篡改监护、木马病毒监控"等网站安全服务，消除了用户所担心的网络安全等问题。
          当可信网站认证
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 50px;
          "
        >
          <div class="title"></div>
          主流搜索引擎及浏览器的展现
        </div>
        <div class="box_contents">
          通过“可信网站”认证后，网站还可以在搜狗、搜搜等搜索平台，充分展示企业真实身份。“可信网站”认证，不仅是数百万网站取信于民的“可信身份证”，是亿万网民判别网站可信与否的重要参考！
        </div>
        <div><img src="@/assets/img/zzbl/28/2.png" alt="" /></div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 50px;
          "
        >
          <div class="title"></div>
          网站为什么要做“可信网站”认证？
        </div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #EE3D33;
            margin-top: 20px;
          "
        >
          诚信隐患
        </div>
        <div class="box_contents">
          现在由于网络的发达，网络购物成了很多人主要的购物方式，大到大宗商品，小到日常家居用品等，都通过网络购物实现。人们在享受互联网带来的方便高效之余,网络骗术的“应运而生”为“真相”蒙上了面纱，网络诈骗手段千变万化，身边的受害者越来越多，虽不致人人自危的地步，但用户对网络信息信任度一路走低，也对诸多企业网站的品牌及发展造成不良影响
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #EE3D33;
            margin-top: 20px;
          "
        >
          权威统计
        </div>
        <div class="box_contents">
          据权威统计，当电子商务用户遇到不信任网站时，92.6%的用户选择退出网站，86,9%的用户选择取消交易，有63.4%的用户会寻找可以验证网站是否可靠的信息。现在的同类的产品网站基本都一样!客户找产品搜索到我们的时候也会搜索出同行网站!那么多大致都一样的网站!客户该如何选择呢?选择一个认为可以信任的网站很关键!
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #EE3D33;
            margin-top: 20px;
          "
        >
          网站安全
        </div>
        <div class="box_contents">
          客户访问我们的网站页面的时候打开速度慢，打不开，或者页面被篡改了我们能即时知道?我相信很多企业都没有考虑到!可信网站认证不仅将能获得真实身份验证服务，还能获得网站运行监护、网页篡改监护、木马病毒扫描等安全运行服务和数据加密传服务。
          当“可信网站”认证服务检测出用户的网站上出现木马时，会以邮件和手机短信的形式通知用户，快速解决网站安全问题。
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            margin-top: 50px;
          "
        >
          案例展示
        </div>
        <div style="display: flex; align-items: center; margin-top: 20px">
          <img
            style="margin-right: 50px"
            src="@/assets/img/zzbl/28/3.png"
            alt=""
          />
          <img
            style="margin-right: 50px"
            src="@/assets/img/zzbl/28/4.png"
            alt=""
          />
          <img
            style="margin-right: 50px"
            src="@/assets/img/zzbl/28/5.png"
            alt=""
          />
          <img
            style="margin-right: 50px"
            src="@/assets/img/zzbl/28/6.png"
            alt=""
          />
        </div>

        <div style="margin-top: 50px">
          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                1、.com,.net,.edu等其他域名可以申请”可信网站”验证么?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                可以</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                1、.com,.net,.edu等其他域名可以申请”可信网站”验证么?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                可以</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                2、网站名称可以重复吗?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                可以</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                3、域名转让后”可信网站”验证可以继续使用么?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                不可以，域名转让”可信网站”验证立即作废。</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                4、“可信网站” 验证为什么每年要进行年检?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                “因为企业的工商信息和网站域名信息会有变更，为了保证网站对应企业的真实存在，保证信息的真实性，”可信网站”验证必须每年都要进行年检。</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                5、没收到发送的email怎么办?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                到验证服务中心处提交补发申请</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                6、可信网站用户服务平台有什么功能?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                可信网站的用户均可登陆用户服务平台进行可信网站的维护，包括信息查询、修改、(0go下载、增值服务开通、维护、登陆运行宝后台等一系列的后续功能。</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                7、怎样开通用户服务平台?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                新用户在审核通过后将受到系统发送的审核通过及邀约邮件,按照邮件中的提示进行平台的激活、初始化和登录即可。旧用户可以登录可信网站用户服务平台进行账户A申领，通过以后买的可信网站信息进行申领，成功后也可登录。</span
              >
            </div>
          </div>

          <div style="margin-top: 30px">
            <div style="display: flex; align-items: center">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_16.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                8、申请后不想继续使用可以退款么?</span
              >
            </div>
            <div style="display: flex; align-items: center; margin-top: 10px">
              <img
                style="width: 30px; height: 30px; margin-right: 20px"
                src="@/assets/img/home/c_17.png"
                alt=""
              />
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                "
              >
                一经发出，概不退款。</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "28",
      middleList: [],
      middleTitle: "什么是可信网站认证?",
      middleContent:
        "可信网站认证，是由我国域名注册管理机构中国互联网络信息中心(CNNIC)提供技术支持，中网推出的第三方网站真实身份验证服务，可信网站认证通过对企业域名、网站、工商登记或组织机构信息进行严格交互审核后，在网站上产生一个可信网站标志，网民可以通过这个可信网站标志来辨别您的网站是不是合法性的和可靠的，企业网站经过可信网站认证后可以打消访客的疑虑，放心的让用户你网站上购买产品，有效提升网站订单率和转换率。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.box_contents {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
  margin: 15px 0;
}
.box_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.box_content {
  flex: 1;
  border: 1px solid #D2D2D2;
  padding: 10px;
  margin-right: 15px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>
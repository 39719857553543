<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto 50px;
          padding-bottom: 30px;
        "
      >
        <div style="margin-bottom: 100px; padding: 0 50px 50px">
          <div class="title" style="margin-bottom: 20px">申请CDN许可证流程</div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              margin-bottom: 45px;
              text-align: center;
            "
          >
            CDN许可证申请全套解决方案
          </div>
          <div style="display: flex">
            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
              <div class="xk_title">成立符合条件的公司</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
              <div class="xk_title">搭建系统进行评测</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
              <div class="xk_title">行政审批流程</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
              <div class="xk_title">领取许可证</div>
            </div>
          </div>
        </div>

        <div style="margin-bottom: 100px; padding: 0 50px 50px">
          <div class="title" style="margin-bottom: 20px">
            申请CDN许可证系统要求
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              margin-bottom: 45px;
              text-align: center;
            "
          >
            CDN许可证申请全套解决方案，详情咨询：400-0066-991
          </div>
          <div>
            <el-table
              :data="tableData"
              border
              style="width: 100%"
              class="custom-table"
            >
              <el-table-column
                width="260px"
                align="center"
                prop="date"
                label="名称"
              >
              </el-table-column>
              <el-table-column align="center" prop="name" label="功能及描述">
              </el-table-column>
              <el-table-column
                width="260px"
                align="center"
                prop="address"
                label="数量"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>

        <div>
          <div class="title">CDN许可证申请代理服务内容</div>
          <div class="title_middle" style="margin: 15px 0">
            全程无须您亲自到场/双向快递、极速拿照
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
              margin-bottom: 20px;
            "
          >
            费用包含：CDN证正本及附页；专业技术方案的提供、专业技术人员派遣、CDN系统、机房部署、难点技术攻关、业务指导
          </div>
          <div style="text-align: center">
            <img src="@/assets/img/zzbl/six/2.png" alt="" />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            <div style="margin-top: 30px">
              证书内容:名称、编号、导语、申办单位名称、法定代表人、注册资本、
            </div>
            <div style="margin: 10px 0; padding-bottom: 70px">
              注册住所、业务种类、业务覆盖范围、签发人、发证机关盖章、发证日期、有效期。
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 90px">
        <div class="title" style="margin: 50px 0">
          办理CDN许可证需要准备的材料
        </div>
        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 10%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                01
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请CDN许可证公司营业执照副本、法定代表人有效期内的二代身份证的原件彩色扫描件。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 6%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                02
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请CDN许可证公司的公司章程原件彩色扫描件。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                03
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                申请ISP许可证公司的企业法人股东的工商营业执照原件彩色扫描件，企业法人股东公司章程原件彩色扫描件(要加盖工商局档案查询章);公司的自然人股东二代身份证原件彩色扫描件。
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
            <div
              style="position: absolute; text-align: center; left: 5%; top: 25%"
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                04
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                3名员工有效期内的二代身份证正反面原件彩色扫描件。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 22%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                05
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                3名员工近一个月的社保证明。
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 19%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                06
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                股权结构图(签定协议后，我司提供)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      tableData: [
        {
          date: "CDN信息安全管理系统",
          name:
            "用于企业对CDN接入资源进行管理并进行有效核查，对CDN基础资源提供管理界面，可实现工信部及通信管理局要求的快速核对定位违法、违规网站进行快速的定位、管控，该系统为申请CDN资质考核要求必建系统。",
          address: "至少2套",
        },
        {
          date: "ICP/IP地址/域名备案管理系统",
          name:
            "用于企业自主进行IDC中备案IP和域名的管理、上报，同时支持对资源的报备进行监测，能够发现未报备IP地址、未报备服务信息和报备有误服务信息等，并及时提醒相关人员。申请IDC资质考核要求必建系统",
          address: "1套",
        },
        {
          date: "IDC/ISP/CDN企业接入资源管理系统",
          name:
            "用于企业对IDC接入资源进行管理并进行有效核查，对ISP基础资源提供管理界面，可实现工信部及通信管理局要求的快速核对定位违法、违规网站进行快速的定位、管控，该系统为申请IDC资质考核要求必建系统。",
          address: "1套",
        },
      ],
      middleImg: "6",
      middleList: [
        {
          title: "内容分发业务(CDN)",
          content: [
            {
              one:
                "利用机房设施，以外包出租的方式为内容分发网络将网站内容发布到最接近用户的边缘节点，使网民可就近取得所需内容。",
              two: "提高网民访问的响应速度和成功率，同时能够保护源站。",
              three:
                "解决由于地域、带宽、运营商接入等问题带来的访问延迟高问题，有效帮助站点提升访问速度。",
              four: "典型客户:蓝汛、阿里云CDN、百度CDN、360CDN、加速乐等",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "哪些企业需要办理CDN经营许可证",
          content: [
            {
              one:
                "为游戏、视频、门户网站、购物网站、企事业单位网站或数据提供网络加速服务的，均需要办理CDN经营许可证",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "内容分发网络(CDN)许可证申请条件",
          content: [
            {
              one: "经营者为依法设立的公司，有与开展经营活动相适应的资金",
              two: "有与开展经营活动相适应的专业人员",
              three: "建设相应的网络和信息安全管理系统;信息安全管理机制及方案",
              four: "具有企业资源和业务管理系统",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "内容分发业务(CDN经营许可证申请)",
      middleContent:
        "内容分发网络(CDN)业务是指利用分布在不同区域的节点服务器群组成流量分配管理网络平台，为用户提供内容的分散存储和高速缓存，并根据网络动态流量和负载状况，将内容分发到快速、稳定的缓存服务器上，提高用户内容的访问响应速度和服务的可用性服务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
::v-deep .el-table th.el-table__cell {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  color: #333333 !important;
}
::v-deep .el-table tr {
  background-color: #f4f4f4 !important;
  font-family: Source Han Sans CN !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
::v-deep .custom-table {
  background-color: #f4f4f4 !important;
  border: 1px solid #ebeef5 !important;
}

::v-deep .el-table__body tr.el-table__row,
.el-table__header tr {
  background-color: transparent !important; /* 防止行和头的背景色与表格冲突 */
}

::v-deep .el-table__cell {
  border-color: #D9D9DA !important; /* 调整单元格的边框颜色 */
  color: #606266 !important; /* 调整文本颜色 */
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>